import React from 'react';
import { Link } from 'react-router-dom';
import { ChefHat } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { OrganizationSwitcher } from '../organization/OrganizationSwitcher';

export function MainNavigation() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const currentOrganization = useAuthStore((state) => state.currentOrganization);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center space-x-8">
            <Link to="/" className="flex items-center space-x-2">
              <ChefHat className="h-8 w-8 text-menioo-green" />
              <span className="text-2xl font-display font-bold text-menioo-navy">
                Menioo
              </span>
            </Link>

            <div className="flex space-x-4">
              <Link
                to="/recipes"
                className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
              >
                Explorer
              </Link>
              
              {isAuthenticated && (
                <>
                  <Link
                    to="/cookbook"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Mes recettes
                  </Link>
                  <Link
                    to="/meal-plan"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Planning
                  </Link>
                  <Link
                    to="/grocery-list"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Liste de course
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {isAuthenticated ? (
              currentUser && currentOrganization && <OrganizationSwitcher />
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-menioo-navy hover:text-menioo-green transition-colors"
                >
                  Connexion
                </Link>
                <Link
                  to="/signup"
                  className="bg-menioo-green text-white px-4 py-2 rounded-md hover:bg-menioo-green-dark transition-colors"
                >
                  Inscription
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}