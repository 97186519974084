import React, { useState } from 'react';
import { Mail, Archive, ViewIcon, ChefHat } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { CategoryView } from '../grocery/CategoryView';
import { RecipeView } from '../grocery/RecipeView';
import { AddCustomItem } from '../grocery/AddCustomItem';

type ViewMode = 'category' | 'recipe';

export function GroceryListSidebar() {
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const [isLoading, setIsLoading] = useState(false);
  const { currentList, archiveCurrentList, addCustomItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes (both private and saved public ones)
  const allRecipes = React.useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  const handleSendEmail = async () => {
    if (!currentList) {
      alert("No active grocery list!");
      return;
    }

    const recipient = prompt("Enter the recipient's email:");
    if (!recipient) {
      alert("Email not sent. No recipient provided.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('https://menioo-backend-production.up.railway.app/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipient,
          subject: "Your Grocery List from Menioo",
          content: generateEmailContent(currentList.items),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert("Grocery list sent successfully!");
    } catch (error) {
      console.error("Failed to send email:", error);
      alert('Failed to send the grocery list');
    } finally {
      setIsLoading(false);
    }
  };

  const generateEmailContent = (items: any[]) => {
    const groupedItems = items.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});

    let emailHTML = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
            h2 { color: #4CAF50; }
            ul { list-style: none; padding: 0; }
            li { margin-bottom: 8px; }
            .checkbox { margin-right: 8px; }
            .category { margin-top: 24px; }
          </style>
        </head>
        <body>
          <h2>Your Grocery List</h2>
    `;

    for (const [category, categoryItems] of Object.entries(groupedItems)) {
      emailHTML += `
        <div class="category">
          <h3>${category}</h3>
          <ul>
            ${categoryItems.map((item: any) => `
              <li>
                <input type="checkbox" class="checkbox" ${item.checked ? 'checked' : ''}/> 
                ${item.name} - ${item.quantity} ${item.unit}
              </li>
            `).join('')}
          </ul>
        </div>
      `;
    }

    emailHTML += `
        </body>
      </html>
    `;

    return emailHTML;
  };

  return (
    <div className="h-[calc(100vh-64px)] flex flex-col bg-white">
      <div className="sticky top-0 z-10 flex-shrink-0 p-4 border-b border-gray-200 bg-white">
        <h2 className="text-xl font-display font-bold text-menioo-navy mb-4">
          Liste de courses
        </h2>

        <div className="flex items-center space-x-2">
          <button
            onClick={() => setViewMode('category')}
            className={`px-3 py-1 rounded-md text-sm ${
              viewMode === 'category'
                ? 'bg-menioo-green text-white'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ViewIcon className="h-4 w-4 inline-block mr-1" />
            Catégories
          </button>
          <button
            onClick={() => setViewMode('recipe')}
            className={`px-3 py-1 rounded-md text-sm ${
              viewMode === 'recipe'
                ? 'bg-menioo-green text-white'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ChefHat className="h-4 w-4 inline-block mr-1" />
            Recettes
          </button>
        </div>
      </div>

      <div className="sticky top-[104px] z-10 flex-shrink-0 p-4 border-b border-gray-200 bg-white">
        <AddCustomItem onAdd={addCustomItem} />
      </div>

      <div className="flex-1 overflow-y-auto">
        {!currentList ? (
          <div className="p-4 text-center text-gray-500">
            No active grocery list
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {viewMode === 'category' ? (
              <CategoryView items={currentList.items} recipes={allRecipes} />
            ) : (
              <RecipeView items={currentList.items} recipes={allRecipes} />
            )}
          </div>
        )}
      </div>

      <div className="sticky bottom-0 z-10 flex-shrink-0 p-4 border-t border-gray-200 bg-white">
        <div className="space-y-2">
          <button
            onClick={handleSendEmail}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Mail className="h-4 w-4 mr-2" />
            Send Email
          </button>
          <button
            onClick={() => archiveCurrentList()}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Archive className="h-4 w-4 mr-2" />
            Archive List
          </button>
        </div>
      </div>
    </div>
  );
}