import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from './store/useAuthStore';
import { useStore } from './store/useStore';
import { useInitializeApp } from './hooks/useInitializeApp';
import { MainLayout } from './components/layout/MainLayout';
import { AdminLayout } from './components/admin/AdminLayout';
import { StandardIngredients } from './components/admin/StandardIngredients';
import { RecipeList } from './components/RecipeList';
import { RecipeDetailNew } from './components/recipe/RecipeDetailNew';
import { GroceryListView } from './components/grocery/GroceryListView';
import { SignInForm } from './components/auth/SignInForm';
import { SignUpApiForm } from './components/auth/SignUpApiForm';
import { ForgotPasswordForm } from './components/auth/ForgotPasswordForm';
import { VerifyEmail } from './components/auth/VerifyEmail';
import { OrganizationManagement } from './components/organization/OrganizationManagement';
import { PublicRecipes } from './components/PublicRecipes';
import { MealPlanDrag } from './components/meal-plan/MealPlanDrag';
import { RecipeFormV2 } from './components/recipe/RecipeFormV2';
import { LandingPage } from './components/LandingPage';



export function App() {
  useInitializeApp();
  
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isLoading = useStore((state) => state.isLoading);
  const error = useStore((state) => state.error);
  const fetchPublicRecipes = useStore((state) => state.fetchPublicRecipes);

  React.useEffect(() => {
    fetchPublicRecipes();
  }, [fetchPublicRecipes]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-red-50 text-red-800 p-4 rounded-md">
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <Routes>

      <Route path="/" element={
        isAuthenticated ? <Navigate to="/recipes" /> : <LandingPage />
      } />

      {/* Admin routes */}
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="ingredients" element={<StandardIngredients />} />
      </Route>

      {/* Auth routes */}
      <Route path="/login" element={
        isAuthenticated ? <Navigate to="/cookbook" /> : <SignInForm />
      } />
      <Route path="/signup" element={
        isAuthenticated ? <Navigate to="/cookbook" /> : <SignUpApiForm />
      } />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/verify-email" element={<VerifyEmail />} />

      {/* Main layout routes */}
      <Route element={<MainLayout />}>
        {/* Public routes */}
        <Route path="/recipes" element={<PublicRecipes />} />
        <Route path="/recipes/:id" element={<RecipeDetailNew />} />

        {/* Protected routes */}
        {isAuthenticated ? (
          <>
            <Route path="/cookbook" element={<RecipeList />} />
            <Route path="/recipes/new" element={<RecipeFormV2 />} />
            <Route path="/recipes/:id/edit" element={<RecipeFormV2 />} />
            <Route path="/meal-plan" element={<MealPlanDrag />} />
            <Route path="/grocery-list" element={<GroceryListView />} />
            <Route path="/organization" element={<OrganizationManagement />} />

          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Route>
    </Routes>
  );
}