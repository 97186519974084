import React, { useEffect, useRef } from 'react';

export function AdSidebar() {
  const adRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    try {
      if (adRef.current) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.error('Error loading ad:', error);
    }
  }, []);

  return (
    <div ref={adRef} className="hidden lg:block">
      <ins
        className="adsbygoogle"
        style={{ display: 'inline-block', width: '300px', height: '600px' }}
        data-ad-client="ca-pub-5534483929017394"
        data-ad-slot="8086968107"
      />
    </div>
  );
}