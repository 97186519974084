import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Droppable } from '@hello-pangea/dnd';
import { Recipe, MealPlan } from '../../types';
import { DraggableRecipe } from './DraggableRecipe';

interface WeeklyPlannerProps {
  weekDays: Date[];
  mealPlan: MealPlan[];
  recipes: Recipe[];
}

const mealTypes = ['breakfast', 'lunch', 'snack', 'dinner'];

export function WeeklyPlanner({ weekDays, mealPlan, recipes }: WeeklyPlannerProps) {
  // Memoize recipe lookup for better performance
  const recipeMap = useMemo(() => {
    return recipes.reduce((acc, recipe) => {
      acc[recipe.id] = recipe;
      return acc;
    }, {} as Record<string, Recipe>);
  }, [recipes]);

  const getRecipeForSlot = (date: Date, mealType: string) => {
    const plannedMeal = mealPlan.find(
      meal => 
        meal.date === format(date, 'yyyy-MM-dd') && 
        meal.mealType === mealType
    );
    
    if (plannedMeal) {
      const recipe = recipeMap[plannedMeal.recipeId];
      return recipe ? { recipe, mealPlanId: plannedMeal.id } : null;
    }
    
    return null;
  };

  // Get unplanned recipes (those with empty mealType)
  const unplannedRecipes = useMemo(() => {
    return mealPlan
      .filter(meal => !meal.mealType)
      .map(meal => {
        const recipe = recipeMap[meal.recipeId];
        return recipe ? { recipe, mealPlanId: meal.id } : null;
      })
      .filter((item): item is { recipe: Recipe; mealPlanId: string } => item !== null);
  }, [mealPlan, recipeMap]);

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      {/* Unplanned recipes section */}
      <div className="border-b border-gray-100">
        <div className="p-4 bg-gray-50">
          <h3 className="text-sm font-medium text-menioo-navy mb-2">
            Recettes non planifiées
          </h3>
          <Droppable droppableId="unplanned" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 min-h-[100px] ${
                  snapshot.isDraggingOver ? 'bg-menioo-green/10' : ''
                }`}
              >
                {unplannedRecipes.map((item, index) => (
                  <DraggableRecipe 
                    key={`${item.recipe.id}-${item.mealPlanId}`}
                    recipe={item.recipe} 
                    index={index}
                    mealPlanId={item.mealPlanId}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>

      {/* Weekly schedule grid */}
      <div className="grid grid-cols-5 divide-x divide-gray-100">
        {/* Meal type headers */}
        <div className="col-span-1"></div>
        {mealTypes.map(type => (
          <div key={type} className="p-2 bg-menioo-cream/50 text-center font-medium text-menioo-navy">
            {type}
          </div>
        ))}

        {/* Days and slots */}
        {weekDays.map((day, dayIndex) => (
          <React.Fragment key={dayIndex}>
            {/* Day header */}
            <div className="p-2 bg-menioo-cream/50 font-medium text-menioo-navy">
              {format(day, 'EEEE', { locale: fr })}
              <div className="text-sm text-gray-500">
                {format(day, 'd MMM', { locale: fr })}
              </div>
            </div>

            {/* Meal slots */}
            {mealTypes.map(mealType => {
              const droppableId = `${format(day, 'yyyy-MM-dd')}_${mealType}`;
              const recipeData = getRecipeForSlot(day, mealType);

              return (
                <Droppable key={droppableId} droppableId={droppableId}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`p-2 min-h-[120px] relative ${
                        snapshot.isDraggingOver ? 'bg-menioo-green/10' : ''
                      }`}
                    >
                      {recipeData && (
                        <DraggableRecipe 
                          key={`${recipeData.recipe.id}-${recipeData.mealPlanId}`}
                          recipe={recipeData.recipe}
                          index={0}
                          mealPlanId={recipeData.mealPlanId}
                          mealType={mealType}
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}