import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useStore } from '../store/useStore';
import { useAuthStore } from '../store/useAuthStore';
import { useCookbookStore } from '../store/useCookbookStore';
import { useMealPlanStore } from '../store/useMealPlanStore';
import { useGroceryListStore } from '../store/useGroceryListStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Organization, User } from '../types';

export function useInitializeApp() {
  const initializeSubscriptions = useStore((state) => state.initializeSubscriptions);
  const initializeCookbookSubscription = useCookbookStore((state) => state.initializeCookbookSubscription);
  const initializeMealPlanSubscription = useMealPlanStore((state) => state.initializeMealPlanSubscription);
  const initializeGroceryListSubscription = useGroceryListStore((state) => state.initializeSubscription);
  const cleanup = useStore((state) => state.cleanup);
  const setAuthState = useAuthStore((state) => state.setAuthState);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const publicPaths = ['/', '/login', '/signup', '/verify-email'];
    let isInitialLoad = true;

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Get user data
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          
          if (!userDoc.exists()) {
            console.warn('User document not found');
            await auth.signOut();
            setAuthState({
              currentUser: null,
              currentOrganization: null,
              isAuthenticated: false,
              error: 'User account not found'
            });
            return;
          }

          const userData = userDoc.data() as User;
          
          // Get organization data using defaultOrganizationId
          const orgDoc = await getDoc(doc(db, 'organizations', userData.defaultOrganizationId));
          
          if (!orgDoc.exists()) {
            console.warn('Organization not found');
            await auth.signOut();
            setAuthState({
              currentUser: null,
              currentOrganization: null,
              isAuthenticated: false,
              error: 'Organization not found'
            });
            return;
          }

          const orgData = orgDoc.data() as Organization;

          // Initialize auth state with user and their default organization
          setAuthState({
            currentUser: { ...userData, id: userDoc.id },
            currentOrganization: { ...orgData, id: orgDoc.id },
            isAuthenticated: true,
            error: null
          });

          // Initialize subscriptions after auth state is set
          const unsubscribeCookbook = initializeCookbookSubscription();
          const unsubscribeMealPlan = initializeMealPlanSubscription();
          const unsubscribeGroceryList = initializeGroceryListSubscription();
          await initializeSubscriptions();

          // Only redirect if coming from login/signup pages
          if (location.pathname === '/login' || location.pathname === '/signup') {
            navigate('/');
          }

          return () => {
            unsubscribeCookbook();
            unsubscribeMealPlan();
            unsubscribeGroceryList();
          };
        } else {
          cleanup();
          setAuthState({
            currentUser: null,
            currentOrganization: null,
            isAuthenticated: false,
            error: null
          });
          
          // Only redirect to login if trying to access protected routes
          if (!isInitialLoad && !publicPaths.includes(location.pathname)) {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error initializing app:', error);
        cleanup();
        setAuthState({
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false,
          error: (error as Error).message
        });
        
        if (!publicPaths.includes(location.pathname)) {
          navigate('/login');
        }
      } finally {
        isInitialLoad = false;
      }
    });

    return () => {
      unsubscribe();
      cleanup();
    };
  }, [
    cleanup,
    initializeSubscriptions,
    initializeCookbookSubscription,
    initializeMealPlanSubscription,
    initializeGroceryListSubscription,
    location.pathname,
    navigate,
    setAuthState
  ]);
}