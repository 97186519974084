import React, { useState } from 'react';
import { UserGroup, Home, Building, ChevronDown, Settings, LogOut, Check } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { Link, useNavigate } from 'react-router-dom';

export function OrganizationSwitcher() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = useAuthStore((state) => state.currentUser);
  const currentOrganization = useAuthStore((state) => state.currentOrganization);
  const logout = useAuthStore((state) => state.logout);
  const switchOrganization = useAuthStore((state) => state.switchOrganization);

  if (!currentUser || !currentOrganization) {
    return null;
  }

  const userOrganizations = Object.entries(currentUser.organizations || {}).map(([orgId, orgData]) => ({
    id: orgId,
    role: orgData.role
  }));

  const handleSwitchOrg = async (orgId: string) => {
    await switchOrganization(orgId);
    setIsOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-100 transition-colors"
      >
        <Home className="w-8 h-8 text-menioo-green" />
        <span className="text-sm font-medium text-menioo-navy">
          {currentOrganization.name}
        </span>
        <ChevronDown className="w-4 h-4 text-gray-400" />
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 z-40"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 py-2 z-50">
            {/* User info */}
            <div className="px-4 py-2 border-b border-gray-100">
              <div className="text-sm font-medium text-menioo-navy">
                {currentUser.firstName} {currentUser.lastName}
              </div>
              <div className="text-xs text-gray-500">{currentUser.email}</div>
            </div>

            {/* Organizations */}
            <div className="py-2 border-b border-gray-100">
              <div className="px-4 py-1 text-xs font-medium text-gray-500 uppercase">
                Organizations
              </div>
              {userOrganizations.map(({ id, role }) => (
                <button
                  key={id}
                  onClick={() => handleSwitchOrg(id)}
                  className="w-full px-4 py-2 text-left flex items-center justify-between hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center space-x-2">
                    <Home className="w-6 h-6 text-menioo-green" />
                    <div>
                      <div className="text-sm text-menioo-navy">
                        {currentOrganization.name}
                      </div>
                      <div className="text-xs text-gray-500 capitalize">
                        {role}
                      </div>
                    </div>
                  </div>
                  {id === currentUser.defaultOrganizationId && (
                    <Check className="w-4 h-4 text-menioo-green" />
                  )}
                </button>
              ))}
            </div>

            {/* Settings and Sign Out */}
            <div className="py-2">
              <Link
                to="/organization"
                className="w-full px-4 py-2 text-left flex items-center space-x-2 hover:bg-gray-50 transition-colors"
                onClick={() => setIsOpen(false)}
              >
                <Settings className="w-4 h-4 text-gray-500" />
                <span className="text-sm text-menioo-navy">Organization Settings</span>
              </Link>
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 text-left flex items-center space-x-2 text-red-600 hover:bg-gray-50 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                <span className="text-sm">Sign out</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}