import React, { useState } from 'react';
import { ChefHat, Plus, Download, Upload, Search, Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useStandardIngredientsStore } from '../../store/useStandardIngredientsStore';
import { downloadCSV, getExampleCSV, parseCSV } from '../../utils/csv';
import { StandardIngredient, LanguageCode } from '../../types/ingredients';
import { IngredientForm } from './IngredientForm';

const LANGUAGES: { code: LanguageCode; label: string; flag: string }[] = [
  { code: 'en', label: 'English', flag: '🇬🇧' },
  { code: 'fr', label: 'Français', flag: '🇫🇷' }
];

export function StandardIngredients() {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const {
    ingredients,
    searchTerm,
    selectedLanguages,
    setSearchTerm,
    toggleLanguage,
    addIngredient,
    updateIngredient,
    removeIngredient,
    initializeSubscription,
    processImage
  } = useStandardIngredientsStore();

  // Initialize subscription
  React.useEffect(() => {
    const unsubscribe = initializeSubscription();
    return () => unsubscribe();
  }, [initializeSubscription]);

  const handleExport = () => {
    // Filter ingredients by selected languages before export
    const filteredIngredients = ingredients.filter(ing => 
      selectedLanguages.includes(ing.language)
    );
    const exportData = filteredIngredients.map(({ id, createdAt, updatedAt, ...rest }) => rest);
    downloadCSV(exportData, 'standard-ingredients.csv');
  };

  const handleExportExample = () => {
    const exampleData = getExampleCSV();
    downloadCSV(exampleData, 'standard-ingredients-example.csv');
  };

  const handleImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const csvText = event.target?.result as string;
        const importedData = parseCSV(csvText);
        
        for (const data of importedData) {
          await addIngredient(data);
        }
        
        alert('Import completed successfully!');
      } catch (error) {
        console.error('Import error:', error);
        alert('Error importing data. Please check the file format.');
      }
    };
    reader.readAsText(file);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this ingredient?')) {
      await removeIngredient(id);
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      if (editingId) {
        await updateIngredient({ id: editingId, ...data });
      } else {
        await addIngredient(data);
      }
      setIsAdding(false);
      setEditingId(null);
    } catch (error) {
      console.error('Error saving ingredient:', error);
      alert('Error saving ingredient. Please try again.');
    }
  };

  const filteredIngredients = ingredients.filter(ingredient =>
    (selectedLanguages.includes(ingredient.language) &&
    (ingredient.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ingredient.aliases.some(alias => alias.toLowerCase().includes(searchTerm.toLowerCase()))))
  );

  const editingIngredient = editingId 
    ? ingredients.find(i => i.id === editingId)
    : null;

  if (isAdding || editingId) {
    return (
      <div>
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-display font-bold text-menioo-navy">
            {editingId ? 'Edit Ingredient' : 'Add New Ingredient'}
          </h2>
          <button
            onClick={() => {
              setIsAdding(false);
              setEditingId(null);
            }}
            className="text-gray-600 hover:text-gray-900"
          >
            Cancel
          </button>
        </div>
        <IngredientForm
          ingredient={editingIngredient}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsAdding(false);
            setEditingId(null);
          }}
          processImage={processImage}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-3">
          <ChefHat className="h-6 w-6 text-menioo-green" />
          <h2 className="text-2xl font-display font-bold text-menioo-navy">
            Standard Ingredients
          </h2>
        </div>
        <div className="flex items-center space-x-3">
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExportExample}
              className="text-sm text-menioo-green hover:text-menioo-green-dark"
            >
              Download Example
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleImport}
              className="hidden"
              id="csv-upload"
            />
            <label
              htmlFor="csv-upload"
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
            >
              <Upload className="h-4 w-4 mr-2" />
              Import CSV
            </label>
            <button
              onClick={handleExport}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Download className="h-4 w-4 mr-2" />
              Export CSV
            </button>
          </div>
          <button
            onClick={() => setIsAdding(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-menioo-green hover:bg-menioo-green-dark"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Ingredient
          </button>
        </div>
      </div>

      {/* Language Filter and Search */}
      <div className="mb-6 flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <Globe className="h-5 w-5 text-gray-400" />
          {LANGUAGES.map(({ code, label, flag }) => (
            <label
              key={code}
              className="inline-flex items-center space-x-2 text-sm"
            >
              <input
                type="checkbox"
                checked={selectedLanguages.includes(code)}
                onChange={() => toggleLanguage(code)}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span>
                {flag} {label}
              </span>
            </label>
          ))}
        </div>
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="search"
            placeholder="Search ingredients..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-menioo-green focus:border-menioo-green sm:text-sm"
          />
        </div>
      </div>

      {/* Ingredients Table */}
      <div className="bg-white shadow-sm rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Language
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Preferred Unit
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Attributes
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredIngredients.map((ingredient) => (
              <tr key={ingredient.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {ingredient.picture && (
                      <img
                        src={ingredient.picture}
                        alt={ingredient.name}
                        className="h-10 w-10 rounded-full mr-3 object-cover"
                      />
                    )}
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {ingredient.name}
                      </div>
                      {ingredient.aliases.length > 0 && (
                        <div className="text-sm text-gray-500">
                          {ingredient.aliases.join(', ')}
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {LANGUAGES.find(l => l.code === ingredient.language)?.flag}{' '}
                    {LANGUAGES.find(l => l.code === ingredient.language)?.label}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="text-sm text-gray-900">
                    {t(`categories.${ingredient.category}`)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="text-sm text-gray-900">
                    {t(`units.${ingredient.preferredUnit}`)}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900 space-y-1">
                    {ingredient.attributes.glutenFree && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
                        Gluten Free
                      </span>
                    )}
                    {ingredient.attributes.vegan && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
                        Vegan
                      </span>
                    )}
                    {ingredient.attributes.vegetarian && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
                        Vegetarian
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => setEditingId(ingredient.id)}
                    className="text-menioo-green hover:text-menioo-green-dark mr-4"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(ingredient.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}