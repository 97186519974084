import { Recipe } from '../types';
import { useGroceryListStore } from '../store/useGroceryListStore';
import { useMealPlanStore } from '../store/useMealPlanStore';
import { collection, addDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function addRecipeToMealPlanAndGrocery(
  recipe: Recipe,
  date: string,
  mealType: string,
  userId: string,
  organizationId: string
): Promise<string> {
  const { currentList, updateCurrentList } = useGroceryListStore.getState();
  const { addToMealPlan } = useMealPlanStore.getState();

  try {
    // Add to meal plan first
    const mealPlanId = await addToMealPlan({
      recipeId: recipe.id,
      date,
      mealType,
      userId,
      organizationId
    });

    // Update grocery list
    if (currentList) {
      const updatedItems = [...currentList.items];
      
      recipe.ingredients.forEach(ingredient => {
        const existingItem = updatedItems.find(item => 
          item.name.toLowerCase() === ingredient.name.toLowerCase() &&
          item.unit === ingredient.unit
        );

        if (existingItem) {
          existingItem.quantity += ingredient.quantity;
          existingItem.recipeIds = [...(existingItem.recipeIds || []), recipe.id];
          existingItem.updatedAt = new Date().toISOString();
        } else {
          updatedItems.push({
            id: crypto.randomUUID(),
            name: ingredient.name,
            quantity: ingredient.quantity,
            unit: ingredient.unit,
            category: ingredient.category,
            checked: false,
            recipeIds: [recipe.id],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          });
        }
      });

      await updateCurrentList(updatedItems);
    } else {
      // Create new list
      const newList = {
        organizationId,
        status: 'active',
        items: recipe.ingredients.map(ingredient => ({
          id: crypto.randomUUID(),
          name: ingredient.name,
          quantity: ingredient.quantity,
          unit: ingredient.unit,
          category: ingredient.category,
          checked: false,
          recipeIds: [recipe.id],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        })),
        startDate: date,
        endDate: new Date(date).toISOString().split('T')[0],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await addDoc(collection(db, 'groceryLists'), newList);
    }

    return mealPlanId;
  } catch (error) {
    console.error('Error adding to meal plan and grocery list:', error);
    throw error;
  }
}

export async function removeRecipeIngredients(recipeId: string, organizationId: string) {
  const { currentList, updateCurrentList } = useGroceryListStore.getState();
  
  if (!currentList) return;

  try {
    // Filter out ingredients that are only used by this recipe
    const updatedItems = currentList.items.filter(item => {
      if (!item.recipeIds?.includes(recipeId)) return true;
      return item.recipeIds.length > 1;
    });

    // Update quantities for shared ingredients
    const finalItems = updatedItems.map(item => {
      if (item.recipeIds?.includes(recipeId)) {
        const recipeCount = item.recipeIds.length;
        return {
          ...item,
          quantity: (item.quantity * (recipeCount - 1)) / recipeCount,
          recipeIds: item.recipeIds.filter(id => id !== recipeId),
          updatedAt: new Date().toISOString()
        };
      }
      return item;
    });

    await updateCurrentList(finalItems);
  } catch (error) {
    console.error('Error removing recipe ingredients:', error);
    throw error;
  }
}

export async function cleanupOnArchive(organizationId: string) {
  const { mealPlan, removeFromMealPlan } = useMealPlanStore.getState();

  try {
    // Remove all unplanned recipes
    const unplannedMeals = mealPlan.filter(meal => 
      meal.organizationId === organizationId && (!meal.mealType || !meal.date)
    );
    
    for (const meal of unplannedMeals) {
      await removeFromMealPlan(meal.id);
    }
  } catch (error) {
    console.error('Error cleaning up meal plan:', error);
    throw error;
  }
}