import { StandardIngredient, LanguageCode } from '../types/ingredients';

export function downloadCSV(data: StandardIngredient[], filename: string) {
  const csvContent = convertToCSV(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function convertToCSV(data: StandardIngredient[]): string {
  if (data.length === 0) return '';

  // Define headers
  const headers = [
    'name',
    'language',
    'category',
    'aliases',
    'preferredUnit',
    'picture',
    'glutenFree',
    'vegan',
    'vegetarian',
    'calories',
    'organic',
    'allergens',
    'seasonal'
  ];

  // Create CSV rows
  const rows = [
    headers.join(','), // Header row
    ...data.map(ingredient => {
      const row = [
        `"${ingredient.name}"`,
        `"${ingredient.language}"`,
        `"${ingredient.category}"`,
        `"${JSON.stringify(ingredient.aliases).replace(/"/g, '""')}"`,
        `"${ingredient.preferredUnit}"`,
        `"${ingredient.picture || ''}"`,
        `"${ingredient.attributes.glutenFree || false}"`,
        `"${ingredient.attributes.vegan || false}"`,
        `"${ingredient.attributes.vegetarian || false}"`,
        `"${ingredient.attributes.calories || ''}"`,
        `"${ingredient.attributes.organic || false}"`,
        `"${JSON.stringify(ingredient.attributes.allergens || []).replace(/"/g, '""')}"`,
        `"${JSON.stringify(ingredient.attributes.seasonal || []).replace(/"/g, '""')}"`
      ];
      return row.join(',');
    })
  ];

  return rows.join('\n');
}

export function parseCSV(csvText: string): Omit<StandardIngredient, 'id' | 'createdAt' | 'updatedAt'>[] {
  const lines = csvText.split('\n');
  const headers = lines[0].toLowerCase().split(',').map(header => header.trim());
  
  return lines.slice(1)
    .filter(line => line.trim())
    .map(line => {
      // Split by comma but respect quoted values
      const values = line.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)?.map(value => {
        value = value.trim();
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1).replace(/""/g, '"');
        }
        return value;
      }) || [];

      // Parse arrays or use empty arrays for empty values
      const parseArray = (value: string) => {
        if (!value) return [];
        try {
          return JSON.parse(value);
        } catch {
          return value.split(';').filter(Boolean);
        }
      };

      // Parse boolean values
      const parseBoolean = (value: string) => {
        return value.toLowerCase() === 'true';
      };

      const ingredient = {
        name: values[0] || '',
        language: (values[1] || 'en').toLowerCase() as LanguageCode,
        category: values[2] || 'other',
        aliases: parseArray(values[3]),
        preferredUnit: values[4] || 'piece',
        picture: values[5] || '',
        attributes: {
          glutenFree: parseBoolean(values[6]),
          vegan: parseBoolean(values[7]),
          vegetarian: parseBoolean(values[8]),
          calories: values[9] ? Number(values[9]) : undefined,
          organic: parseBoolean(values[10]),
          allergens: parseArray(values[11]),
          seasonal: parseArray(values[12])
        }
      };

      // Validate required fields
      if (!ingredient.name || !ingredient.language || !ingredient.category) {
        throw new Error('Missing required fields: name, language, and category are required');
      }

      return ingredient;
    });
}

export function getExampleCSV(): Omit<StandardIngredient, 'id' | 'createdAt' | 'updatedAt'>[] {
  return [
    {
      name: "Carrot",
      language: "en",
      category: "produce",
      aliases: ["Baby Carrot"],
      preferredUnit: "gram",
      picture: "",
      attributes: {
        glutenFree: true,
        vegan: true,
        vegetarian: true,
        calories: 41,
        organic: false,
        allergens: [],
        seasonal: ["Spring", "Fall"]
      }
    },
    {
      name: "Carotte",
      language: "fr",
      category: "produce",
      aliases: ["Carotte Nouvelle"],
      preferredUnit: "gram",
      picture: "",
      attributes: {
        glutenFree: true,
        vegan: true,
        vegetarian: true,
        calories: 41,
        organic: false,
        allergens: [],
        seasonal: ["Printemps", "Automne"]
      }
    }
  ];
}