import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChefHat } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { OrganizationSettings } from './OrganizationSettings';
import { MembersList } from './MembersList';
import { SubscriptionPlans } from './SubscriptionPlans';

export function OrganizationManagement() {
  const navigate = useNavigate();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const organization = useAuthStore((state) => state.currentOrganization);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  if (!organization || !currentUser) {
    return (
      <div className="min-h-[60vh] flex flex-col items-center justify-center">
        <ChefHat className="h-16 w-16 text-menioo-green mb-4" />
        <p className="text-menioo-navy font-medium">Loading organization details...</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <OrganizationSettings />
      <MembersList />
      <SubscriptionPlans />
    </div>
  );
}