import React, { useState } from 'react';
import { 
  Clock, 
  Users, 
  Trash2, 
  Link as LinkIcon, 
  Pencil, 
  ChevronRight, 
  AlertCircle, 
  BookmarkPlus,
  BookmarkMinus,
  CalendarPlus
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Recipe } from '../../types';
import { useCookbookStore } from '../../store/useCookbookStore';
import { useAuthStore } from '../../store/useAuthStore';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';

interface RecipeCardProps {
  recipe: Recipe;
  canEdit: boolean;
  isDiscoveryPage?: boolean;
  onDelete: (id: string) => Promise<void>;
}

export function RecipeCard({ recipe, canEdit, isDiscoveryPage = false, onDelete }: RecipeCardProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const addToCookbook = useCookbookStore((state) => state.addToCookbook);
  const removeFromCookbook = useCookbookStore((state) => state.removeFromCookbook);
  const isInCookbook = useCookbookStore((state) => state.isInCookbook);

  const handleDelete = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to delete this recipe?')) {
      return;
    }

    setIsDeleting(true);
    setDeleteError(null);

    try {
      await onDelete(recipe.id);
    } catch (error) {
      setDeleteError('Failed to delete recipe. Please check your permissions.');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAddToCookbook = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await addToCookbook(recipe.id);
    } catch (error) {
      console.error('Error adding to cookbook:', error);
    }
  };

  const handleRemoveFromCookbook = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await removeFromCookbook(recipe.id);
    } catch (error) {
      console.error('Error removing from cookbook:', error);
    }
  };

  const handleAddToMealPlan = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!currentUser?.defaultOrganizationId) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        new Date().toISOString().split('T')[0],
        '',
        currentUser.id,
        currentUser.defaultOrganizationId
      );
    } catch (error) {
      console.error('Error adding to meal plan and grocery list:', error);
    }
  };

  const showAddToCookbook = isDiscoveryPage && isAuthenticated && !isInCookbook(recipe.id);
  const showRemoveFromCookbook = !isDiscoveryPage && recipe.isPublic;

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-all duration-200 border border-gray-100">
      {deleteError && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <p className="text-sm text-red-700">{deleteError}</p>
          </div>
        </div>
      )}

      <Link to={`/recipes/${recipe.id}`} className="block">
        {recipe.image && (
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={recipe.image}
              alt={recipe.name}
              className="w-full h-48 object-cover"
            />
          </div>
        )}
      </Link>
      <div className="p-6">
        <div className="flex items-center justify-between">
          <Link 
            to={`/recipes/${recipe.id}`}
            className="text-lg font-display font-semibold text-menioo-navy hover:text-menioo-green transition-colors"
          >
            {recipe.name}
          </Link>
          <div className="flex items-center space-x-2">
            {recipe.sourceUrl && (
              <a
                href={recipe.sourceUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-menioo-green hover:text-menioo-green-dark transition-colors"
                title="View source"
              >
                <LinkIcon className="h-4 w-4" />
              </a>
            )}
            {isAuthenticated && (
              <button
                onClick={handleAddToMealPlan}
                className="text-menioo-orange hover:text-menioo-orange-dark transition-colors"
                title="Add to meal plan"
              >
                <CalendarPlus className="h-4 w-4" />
              </button>
            )}
            {showAddToCookbook && (
              <button
                onClick={handleAddToCookbook}
                className="text-menioo-green hover:text-menioo-green-dark transition-colors"
                title="Add to cookbook"
              >
                <BookmarkPlus className="h-4 w-4" />
              </button>
            )}
            {showRemoveFromCookbook && (
              <button
                onClick={handleRemoveFromCookbook}
                className="text-red-500 hover:text-red-600 transition-colors"
                title="Remove from cookbook"
              >
                <BookmarkMinus className="h-4 w-4" />
              </button>
            )}
            {canEdit && (
              <>
                <Link
                  to={`/recipes/${recipe.id}/edit`}
                  className="text-menioo-navy hover:text-menioo-green transition-colors"
                  title="Edit recipe"
                >
                  <Pencil className="h-4 w-4" />
                </Link>
                <button
                  onClick={handleDelete}
                  disabled={isDeleting}
                  className="text-red-500 hover:text-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  title="Delete recipe"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center text-sm text-gray-600">
          <Clock className="h-4 w-4 mr-1 text-menioo-orange" />
          {recipe.prepTime + recipe.cookTime} mins
          <Users className="h-4 w-4 ml-4 mr-1 text-menioo-orange" />
          {recipe.servings} portions
        </div>
        <div className="mt-4">
          <h4 className="font-medium text-menioo-navy">Ingrédients:</h4>
          <ul className="mt-2 space-y-1">
            {recipe.ingredients.slice(0, 3).map((ingredient) => (
              <li key={ingredient.id} className="text-sm text-gray-600">
                {ingredient.quantity} {ingredient.unit} {ingredient.name}
              </li>
            ))}
            {recipe.ingredients.length > 3 && (
              <li className="text-sm text-gray-500 italic">
                +{recipe.ingredients.length - 3} more...
              </li>
            )}
          </ul>
        </div>
        <div className="mt-6 flex justify-end">
          <Link
            to={`/recipes/${recipe.id}`}
            className="text-menioo-green hover:text-menioo-green-dark transition-colors flex items-center"
          >
            Détails
            <ChevronRight className="h-4 w-4 ml-1" />
          </Link>
        </div>
      </div>
    </div>
  );
}