export type StandardUnit = 
  | 'piece'
  | 'gram'
  | 'kilogram'
  | 'milliliter'
  | 'centiliter'
  | 'deciliter'
  | 'liter'
  | 'teaspoon'
  | 'tablespoon'
  | 'cup';

export interface UnitTranslation {
  en: string;
  fr: string;
  abbreviation: string;
}

export const standardUnits: Record<StandardUnit, UnitTranslation> = {
  piece: {
    en: 'Piece',
    fr: 'Pièce',
    abbreviation: 'pc'
  },
  gram: {
    en: 'Gram',
    fr: 'Gramme',
    abbreviation: 'g'
  },
  kilogram: {
    en: 'Kilogram',
    fr: 'Kilogramme',
    abbreviation: 'kg'
  },
  milliliter: {
    en: 'Milliliter',
    fr: 'Millilitre',
    abbreviation: 'ml'
  },
  centiliter: {
    en: 'Centiliter',
    fr: 'Centilitre',
    abbreviation: 'cl'
  },
  deciliter: {
    en: 'Deciliter',
    fr: 'Décilitre',
    abbreviation: 'dl'
  },
  liter: {
    en: 'Liter',
    fr: 'Litre',
    abbreviation: 'l'
  },
  teaspoon: {
    en: 'Teaspoon',
    fr: 'Cuillère à Café',
    abbreviation: 'tsp'
  },
  tablespoon: {
    en: 'Tablespoon',
    fr: 'Cuillère à Soupe',
    abbreviation: 'tbsp'
  },
  cup: {
    en: 'Cup',
    fr: 'Tasse',
    abbreviation: 'cup'
  }
};

export const unitConversions: Record<StandardUnit, Record<StandardUnit, number>> = {
  // Volume conversions
  milliliter: {
    centiliter: 0.1,
    deciliter: 0.01,
    liter: 0.001,
    teaspoon: 0.202884,
    tablespoon: 0.067628,
    cup: 0.004227
  },
  centiliter: {
    milliliter: 10,
    deciliter: 0.1,
    liter: 0.01,
    teaspoon: 2.02884,
    tablespoon: 0.67628,
    cup: 0.04227
  },
  deciliter: {
    milliliter: 100,
    centiliter: 10,
    liter: 0.1,
    teaspoon: 20.2884,
    tablespoon: 6.7628,
    cup: 0.4227
  },
  liter: {
    milliliter: 1000,
    centiliter: 100,
    deciliter: 10,
    teaspoon: 202.884,
    tablespoon: 67.628,
    cup: 4.227
  },
  // Weight conversions
  gram: {
    kilogram: 0.001
  },
  kilogram: {
    gram: 1000
  },
  // Volume to volume conversions for cooking
  teaspoon: {
    milliliter: 4.92892,
    tablespoon: 0.333333,
    cup: 0.0208333
  },
  tablespoon: {
    milliliter: 14.7868,
    teaspoon: 3,
    cup: 0.0625
  },
  cup: {
    milliliter: 236.588,
    teaspoon: 48,
    tablespoon: 16
  },
  // No conversions for pieces
  piece: {}
};