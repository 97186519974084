import { create } from 'zustand';
import { 
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  query,
  where,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Cookbook } from '../types';
import { useAuthStore } from './useAuthStore';

interface CookbookStore {
  publicRecipeIds: string[];
  searchTerm: string;
  isLoading: boolean;
  error: string | null;
  setSearchTerm: (term: string) => void;
  addToCookbook: (recipeId: string) => Promise<void>;
  removeFromCookbook: (recipeId: string) => Promise<void>;
  isInCookbook: (recipeId: string) => boolean;
  initializeCookbookSubscription: () => () => void;
}

export const useCookbookStore = create<CookbookStore>((set, get) => ({
  publicRecipeIds: [],
  searchTerm: '',
  isLoading: false,
  error: null,

  setSearchTerm: (term: string) => {
    set({ searchTerm: term });
  },

  addToCookbook: async (recipeId: string) => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) return;

    set({ isLoading: true, error: null });

    try {
      const cookbookRef = doc(db, 'cookbooks', currentUser.defaultOrganizationId);
      const cookbookDoc = await getDoc(cookbookRef);

      if (!cookbookDoc.exists()) {
        // Create new cookbook if it doesn't exist
        await setDoc(cookbookRef, {
          organizationId: currentUser.defaultOrganizationId,
          publicRecipeIds: [recipeId],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      } else {
        // Update existing cookbook
        await updateDoc(cookbookRef, {
          publicRecipeIds: [...cookbookDoc.data().publicRecipeIds, recipeId],
          updatedAt: serverTimestamp()
        });
      }
    } catch (error) {
      console.error('Error adding to cookbook:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  removeFromCookbook: async (recipeId: string) => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) return;

    set({ isLoading: true, error: null });

    try {
      const cookbookRef = doc(db, 'cookbooks', currentUser.defaultOrganizationId);
      const cookbookDoc = await getDoc(cookbookRef);

      if (cookbookDoc.exists()) {
        const publicRecipeIds = cookbookDoc.data().publicRecipeIds.filter(
          (id: string) => id !== recipeId
        );
        
        await updateDoc(cookbookRef, {
          publicRecipeIds,
          updatedAt: serverTimestamp()
        });
      }
    } catch (error) {
      console.error('Error removing from cookbook:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  isInCookbook: (recipeId: string) => {
    return get().publicRecipeIds.includes(recipeId);
  },

  initializeCookbookSubscription: () => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) {
      console.error('No default organization ID found');
      return () => {};
    }

    const cookbookQuery = query(
      collection(db, 'cookbooks'),
      where('organizationId', '==', currentUser.defaultOrganizationId)
    );

    const unsubscribe = onSnapshot(cookbookQuery,
      (snapshot) => {
        if (!snapshot.empty) {
          const cookbook = snapshot.docs[0].data() as Cookbook;
          set({ publicRecipeIds: cookbook.publicRecipeIds || [], error: null });
        } else {
          set({ publicRecipeIds: [], error: null });
        }
      },
      (error) => {
        console.error('Error in cookbook subscription:', error);
        set({ error: error.message });
      }
    );

    return unsubscribe;
  }
}));