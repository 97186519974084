import React from 'react';
import { Clock, Users, Trash2, X } from 'lucide-react';
import { Recipe } from '../../types';
import { useMealPlanStore } from '../../store/useMealPlanStore';
import { removeRecipeIngredients } from '../../utils/mealPlanUtils';
import { useAuthStore } from '../../store/useAuthStore';

interface RecipeTileProps {
  recipe: Recipe;
  mealPlanId?: string;
  mealType?: string;
  onRemoveFromDay?: boolean;
}

export function RecipeTile({ recipe, mealPlanId, mealType, onRemoveFromDay }: RecipeTileProps) {
  const removeFromMealPlan = useMealPlanStore((state) => state.removeFromMealPlan);
  const updateMealPlan = useMealPlanStore((state) => state.updateMealPlan);
  const currentUser = useAuthStore((state) => state.currentUser);

  const handleRemoveFromMealPlan = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!mealPlanId || !currentUser?.defaultOrganizationId) return;

    try {
      await removeRecipeIngredients(recipe.id, currentUser.defaultOrganizationId);
      await removeFromMealPlan(mealPlanId);
    } catch (error) {
      console.error('Error removing from meal plan:', error);
    }
  };

  const handleClearMealType = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (mealPlanId) {
      try {
        await updateMealPlan(mealPlanId, {
          mealType: '',
          date: '',
        });
      } catch (error) {
        console.error('Error clearing meal type:', error);
      }
    }
  };

  return (
    <div className="group bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden hover:shadow-md transition-shadow">
      <div className="relative">
        {recipe.image && (
          <img
            src={recipe.image}
            alt={recipe.name}
            className="w-full h-32 object-cover"
          />
        )}
        {mealPlanId && (
          <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {/* "X" button only when `mealType` exists */}
            {mealType && onRemoveFromDay && (
              <button
                onClick={handleClearMealType}
                className="p-1 bg-white/90 backdrop-blur-sm rounded-full shadow-sm hover:bg-red-50 transition-colors"
                title="Clear meal slot"
              >
                <X className="h-4 w-4 text-red-500" />
              </button>
            )}

            {/* "Trash" button always displayed when `mealPlanId` exists */}
            <button
              onClick={handleRemoveFromMealPlan}
              className="p-1 bg-white/90 backdrop-blur-sm rounded-full shadow-sm hover:bg-red-50 transition-colors"
              title="Remove from meal plan"
            >
              <Trash2 className="h-4 w-4 text-red-500" />
            </button>
          </div>
        )}
      </div>
      <div className="p-3">
        <h3 className="font-medium text-menioo-navy truncate" title={recipe.name}>
          {recipe.name}
        </h3>
        <div className="mt-1 flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            <span>{recipe.prepTime + recipe.cookTime} mins</span>
          </div>
          <div className="flex items-center">
            <Users className="h-4 w-4 mr-1" />
            <span>{recipe.servings}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
