import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle, ChefHat, Check } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { MainNavigation } from '../navigation/MainNavigation';

export function ForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const sendPasswordReset = useAuthStore((state) => state.sendPasswordReset);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    
    try {
      await sendPasswordReset(email);
      setIsSuccess(true);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <div className="min-h-screen bg-menioo-cream">
        <MainNavigation />
        <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full text-center">
            <div className="rounded-full bg-menioo-green/10 p-3 mx-auto w-fit">
              <Check className="h-6 w-6 text-menioo-green" />
            </div>
            <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
              Vérifiez vos emails
            </h2>
            <p className="mt-2 text-gray-600">
              Nous vous avons envoyé un mail avec un lien de réinitialisation
            </p>
            <Link
              to="/login"
              className="mt-8 inline-block text-menioo-green hover:text-menioo-green-dark transition-colors"
            >
              Retour à la page de connexion
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-menioo-cream">
      <MainNavigation />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <div className="flex justify-center">
              <div className="rounded-full bg-white p-3 shadow-md">
                <ChefHat className="h-12 w-12 text-menioo-green" />
              </div>
            </div>
            <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
              Réinitialiser le mot de passe
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Entrez votre email et recevez un lien pour réinitialiser votre mot de passe
            </p>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-menioo-navy">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              />
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-menioo-green hover:bg-menioo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-menioo-green transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Envoi...' : 'Réinitialiser'}
              </button>
            </div>

            <div className="text-center">
              <Link
                to="/login"
                className="text-sm text-menioo-green hover:text-menioo-green-dark transition-colors"
              >
                Retour à la page de connexion
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}