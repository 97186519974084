import React, { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { Check, AlertCircle, ChefHat, Loader } from 'lucide-react';

export function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get('token');
      const email = searchParams.get('email');
      
      if (!token || !email) {
        setError('Invalid verification link');
        setIsVerifying(false);
        return;
      }

      try {
        const response = await fetch('https://menioo-backend-production.up.railway.app/api/auth/verify-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, email }),
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message || 'Failed to verify email');
        }

        setIsVerified(true);
        // Redirect to signin page after 3 seconds
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        console.error('Error verifying email:', error);
        setError((error as Error).message);
      } finally {
        setIsVerifying(false);
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  if (isVerifying) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-menioo-cream px-4">
        <div className="text-center">
          <Loader className="h-8 w-8 animate-spin text-menioo-green mx-auto" />
          <h2 className="mt-4 text-xl font-display font-bold text-menioo-navy">
            Verifying your email...
          </h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-menioo-cream px-4">
        <div className="max-w-md w-full text-center">
          <div className="rounded-full bg-red-100 p-3 mx-auto w-fit">
            <AlertCircle className="h-6 w-6 text-red-600" />
          </div>
          <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
            Verification Failed
          </h2>
          <p className="mt-2 text-gray-600">{error}</p>
          <Link
            to="/login"
            className="mt-8 inline-block text-menioo-green hover:text-menioo-green-dark transition-colors"
          >
            Return to sign in
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-menioo-cream px-4">
      <div className="max-w-md w-full text-center">
        <div className="rounded-full bg-menioo-green/10 p-3 mx-auto w-fit">
          <Check className="h-6 w-6 text-menioo-green" />
        </div>
        <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
          Email Verified!
        </h2>
        <p className="mt-2 text-gray-600">
          Your email has been successfully verified. You will be redirected to the sign in page in a few seconds.
        </p>
        <Link
          to="/login"
          className="mt-8 inline-block bg-menioo-green text-white px-6 py-2 rounded-md hover:bg-menioo-green-dark transition-colors"
        >
          Sign in now
        </Link>
      </div>
    </div>
  );
}