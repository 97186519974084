import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainNavigation } from '../navigation/MainNavigation';
import { SubNavigation } from '../navigation/SubNavigation';
import { AdBanner } from '../ads/AdBanner';
import { AdSidebar } from '../ads/AdSidebar';

export function MainLayout() {
  return (
    <div className="min-h-screen bg-menioo-cream">
      <MainNavigation />
      <SubNavigation />
      
      {/* Top Banner Ad */}
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-2">
        <AdBanner />
      </div>

      <main className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4">
        <div className="flex gap-6">
          {/* Main Content */}
          <div className="flex-1">
            <Outlet />
          </div>

          {/* Sidebar Ad */}
          <aside className="hidden lg:block">
            <div className="sticky top-4">
              <AdSidebar />
            </div>
          </aside>
        </div>
      </main>

      <footer className="bg-menioo-navy text-white py-6 mt-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
          <div className="flex justify-between items-center">
            <div className="text-sm">
              © {new Date().getFullYear()} Menioo. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}