import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertCircle, Check, ChefHat } from 'lucide-react';
import { z } from 'zod';
import { MainNavigation } from '../navigation/MainNavigation';

const signupSchema = z.object({
  firstName: z.string().min(2, 'First name must be at least 2 characters'),
  lastName: z.string().min(2, 'Last name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number'),
  organizationName: z.string().min(2, 'Organization name must be at least 2 characters')
});

type SignupFormData = z.infer<typeof signupSchema>;

export function SignUpApiForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<Partial<SignupFormData>>({});
  const [serverError, setServerError] = useState<string | null>(null);
  const navigate = useNavigate();

  const validateForm = (data: SignupFormData) => {
    try {
      signupSchema.parse(data);
      setFormErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors: Partial<SignupFormData> = {};
        error.errors.forEach((err) => {
          if (err.path[0]) {
            errors[err.path[0] as keyof SignupFormData] = err.message;
          }
        });
        setFormErrors(errors);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setServerError(null);
    
    const formData = new FormData(e.currentTarget);
    const data = {
      firstName: formData.get('firstName') as string,
      lastName: formData.get('lastName') as string,
      email: formData.get('email') as string,
      password: formData.get('password') as string,
      organizationName: formData.get('organizationName') as string
    };

    if (!validateForm(data)) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://menioo-backend-production.up.railway.app/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create account');
      }

      setIsSuccess(true);
    } catch (error) {
      console.error('Signup error:', error);
      setServerError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <div className="min-h-screen bg-menioo-cream">
        <MainNavigation />
        <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full text-center">
            <div className="rounded-full bg-menioo-green/10 p-3 mx-auto w-fit">
              <Check className="h-6 w-6 text-menioo-green" />
            </div>
            <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
              Compte créé avec succès !
            </h2>
            <p className="mt-2 text-gray-600">
              Vous pouvez maintenant vous connecter à votre compte
            </p>
            <Link
              to="/login"
              className="mt-8 inline-block text-menioo-green hover:text-menioo-green-dark transition-colors"
            >
             Aller à la page de connexion
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-menioo-cream">
      <MainNavigation />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <div className="flex justify-center">
              <div className="rounded-full bg-white p-3 shadow-md">
                <ChefHat className="h-12 w-12 text-menioo-green" />
              </div>
            </div>
            <h2 className="mt-6 text-3xl font-display font-bold text-menioo-navy">
              Créer un compte
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              ou{' '}
              <Link
                to="/login"
                className="font-medium text-menioo-green hover:text-menioo-green-dark transition-colors"
              >
                se connecter à votre compte
              </Link>
            </p>
          </div>

          {serverError && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{serverError}</h3>
                </div>
              </div>
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-menioo-navy">
                    Prénom
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      formErrors.firstName
                        ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:border-menioo-green focus:ring-menioo-green'
                    }`}
                  />
                  {formErrors.firstName && (
                    <p className="mt-1 text-sm text-red-600">{formErrors.firstName}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-menioo-navy">
                    Nom
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                      formErrors.lastName
                        ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:border-menioo-green focus:ring-menioo-green'
                    }`}
                  />
                  {formErrors.lastName && (
                    <p className="mt-1 text-sm text-red-600">{formErrors.lastName}</p>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-menioo-navy">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                    formErrors.email
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-menioo-green focus:ring-menioo-green'
                  }`}
                />
                {formErrors.email && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.email}</p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-menioo-navy">
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                    formErrors.password
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-menioo-green focus:ring-menioo-green'
                  }`}
                />
                {formErrors.password && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.password}</p>
                )}
              </div>

              <div>
                <label htmlFor="organizationName" className="block text-sm font-medium text-menioo-navy">
                  Nom du "foyer"
                </label>
                <input
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  required
                  className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                    formErrors.organizationName
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-menioo-green focus:ring-menioo-green'
                  }`}
                />
                {formErrors.organizationName && (
                  <p className="mt-1 text-sm text-red-600">{formErrors.organizationName}</p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-menioo-green hover:bg-menioo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-menioo-green transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Création du compte...' : 'Créer un compte'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}