import React, { useState } from 'react';
import { Users, UserPlus, Trash2 } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { UserRole } from '../../types';

export function MembersList() {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState<UserRole>('contributor');

  const organization = useAuthStore((state) => state.currentOrganization);
  const currentUser = useAuthStore((state) => state.currentUser);
  const addUserToOrganization = useAuthStore((state) => state.addUserToOrganization);
  const removeUserFromOrganization = useAuthStore((state) => state.removeUserFromOrganization);
  const updateUserRole = useAuthStore((state) => state.updateUserRole);

  if (!organization || !currentUser) return null;

  const isOwner = currentUser.organizations[organization.id]?.role === 'owner';

  const handleAddUser = (e: React.FormEvent) => {
    e.preventDefault();
    addUserToOrganization(newUserEmail, newUserRole);
    setNewUserEmail('');
    setNewUserRole('contributor');
  };

  const translateRole = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Propriétaire';
      case 'contributor':
        return 'Contributeur';
      case 'viewer':
        return 'Lecteur';
      default:
        return role;
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl p-6 border border-gray-100">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-3">
          <Users className="h-6 w-6 text-menioo-green" />
          <h2 className="text-xl font-display font-bold text-menioo-navy">
            Membres
          </h2>
        </div>

        {isOwner && (
          <form onSubmit={handleAddUser} className="flex gap-2">
            <input
              type="email"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              placeholder="Adresse email"
              className="rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              required
            />
            <select
              value={newUserRole}
              onChange={(e) => setNewUserRole(e.target.value as UserRole)}
              className="rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
            >
              <option value="contributor">Contributeur</option>
              <option value="viewer">Lecteur</option>
            </select>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-menioo-green hover:bg-menioo-green-dark transition-colors"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Ajouter
            </button>
          </form>
        )}
      </div>

      <div className="overflow-hidden rounded-lg border border-gray-100">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-menioo-cream">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-menioo-navy uppercase tracking-wider">
                Utilisateur
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-menioo-navy uppercase tracking-wider">
                Rôle
              </th>
              {isOwner && (
                <th className="px-6 py-3 text-right text-xs font-medium text-menioo-navy uppercase tracking-wider">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
            {organization.members.map((user) => (
              <tr key={`${user.id}-${user.email}`} className="group hover:bg-menioo-cream/30 transition-colors">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <div className="h-10 w-10 rounded-full bg-menioo-green/10 flex items-center justify-center">
                        <Users className="h-6 w-6 text-menioo-green" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-menioo-navy">
                        {user.name}
                      </div>
                      <div className="text-sm text-gray-500">{user.email}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {isOwner && user.id !== currentUser.id ? (
                    <select
                      value={user.role}
                      onChange={(e) => updateUserRole(user.id, e.target.value as UserRole)}
                      className="rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
                    >
                      <option value="owner">Propriétaire</option>
                      <option value="contributor">Contributeur</option>
                      <option value="viewer">Lecteur</option>
                    </select>
                  ) : (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-menioo-green/10 text-menioo-green">
                      {translateRole(user.role)}
                    </span>
                  )}
                </td>
                {isOwner && (
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {user.id !== currentUser.id && (
                      <button
                        onClick={() => removeUserFromOrganization(user.id)}
                        className="text-red-500 hover:text-red-600 transition-colors"
                        title="Supprimer le membre"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}