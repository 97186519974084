import React from 'react';
import { Users } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';

export function OrganizationSettings() {
  const organization = useAuthStore((state) => state.currentOrganization);
  const updateOrganization = useAuthStore((state) => state.updateOrganization);
  const currentUser = useAuthStore((state) => state.currentUser);

  const handleUpdateName = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    await updateOrganization({ name: formData.get('name') as string });
  };

  if (!organization || !currentUser) return null;

  const isOwner = currentUser.organizations[organization.id]?.role === 'owner';

  return (
    <div className="bg-white shadow-sm rounded-xl p-6 border border-gray-100">
      <div className="flex items-center space-x-3 mb-6">
        <Users className="h-6 w-6 text-menioo-green" />
        <h2 className="text-xl font-display font-bold text-menioo-navy">
          Paramètres du foyer
        </h2>
      </div>

      <form onSubmit={handleUpdateName} className="space-y-6 max-w-lg">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-menioo-navy">
            Nom du foyer
          </label>
          <input
            type="text"
            name="name"
            id="name"
            defaultValue={organization.name}
            disabled={!isOwner}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm disabled:bg-gray-50 disabled:text-gray-500"
          />
          {!isOwner && (
            <p className="mt-1 text-sm text-gray-500">
              Seuls les propriétaires peuvent modifier le nom du foyer
            </p>
          )}
        </div>

        {isOwner && (
          <button
            type="submit"
            className="bg-menioo-green text-white px-4 py-2 rounded-md hover:bg-menioo-green-dark transition-colors focus:outline-none focus:ring-2 focus:ring-menioo-green focus:ring-offset-2"
          >
            Mettre à jour
          </button>
        )}
      </form>
    </div>
  );
}