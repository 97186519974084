import React, { useState } from 'react';
import { ViewIcon, Archive, ChefHat, ShoppingCart, Mail } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { CategoryView } from './CategoryView';
import { RecipeView } from './RecipeView';
import { AddCustomItem } from './AddCustomItem';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';

type ViewMode = 'category' | 'recipe';

export function GroceryListView() {
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const { currentList, archiveCurrentList, addCustomItem, isLoading, error } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);
  const [loading, setIsLoading] = useState(false);

  // Get all available recipes (both private and saved public ones)
  const allRecipes = React.useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  const handleArchive = async () => {
    if (window.confirm('Are you sure you want to archive this grocery list?')) {
      await archiveCurrentList();
    }
  };

  if (!currentList) {
    return (
      <div className="min-h-[40vh] flex flex-col items-center justify-center bg-white rounded-xl shadow-sm p-8">
        <ShoppingCart className="h-16 w-16 text-menioo-green/20 mb-4" />
        <p className="text-menioo-navy font-medium">No active grocery list</p>
        <p className="text-gray-500 text-sm mt-2">
          Add recipes to your meal plan to generate a list
        </p>
      </div>
    );
  }
  const handleSendEmail = async () => {
  if (!currentList) {
    alert("No active grocery list to send!");
    return;
  }

  const emailContent = generateEmailContent(currentList.items);
  const recipient = prompt("Enter the recipient's email:");

  if (!recipient) {
    alert("Email not sent. No recipient provided.");
    return;
  }

  try {
    setIsLoading(true);
    const response = await fetch('https://menioo-backend-production.up.railway.app/api/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        recipient,
        subject: "Your Grocery List from Menioo",
        content: emailContent,
      }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(`Failed to send email: ${errorDetails.message || 'Unknown error'}`);
    }

    alert("Grocery list sent successfully!");
  } catch (error) {
    console.error("Failed to send email:", error);
    alert(`Failed to send the grocery list. Error: ${error.message}`);
  } finally {
    setIsLoading(false);
  }
};
  const generateEmailContent = (items) => {
  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  let emailHTML = `
    <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
          h2 { color: #4CAF50; }
          ul { list-style: none; padding: 0; }
          li { margin-bottom: 8px; }
          .checkbox { margin-right: 8px; }
          .category { margin-top: 24px; }
        </style>
      </head>
      <body>
        <h2>Your Grocery List</h2>
  `;

  for (const [category, items] of Object.entries(groupedItems)) {
    emailHTML += `
      <div class="category">
        <h3>${category}</h3>
        <ul>
          ${items
            .map(
              (item) => `
                <li>
                  <input type="checkbox" class="checkbox" /> 
                  ${item.name} - ${item.quantity} ${item.unit}
                </li>
              `
            )
            .join("")}
        </ul>
      </div>
    `;
  }

  emailHTML += `
      </body>
    </html>
  `;

  return emailHTML;
};

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <ShoppingCart className="h-6 w-6 text-menioo-green" />
          <h2 className="text-2xl font-display font-bold text-menioo-navy">
            Liste de course
          </h2>
        </div>

        <div className="flex items-center space-x-4">
          {/* View toggle */}
          <div className="flex items-center bg-white rounded-lg shadow-sm border border-gray-200">
            <button
              onClick={() => setViewMode('category')}
              className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                viewMode === 'category'
                  ? 'bg-menioo-green text-white'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <ViewIcon className="h-4 w-4 inline-block mr-2" />
              Catégories
            </button>
            <button
              onClick={() => setViewMode('recipe')}
              className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                viewMode === 'recipe'
                  ? 'bg-menioo-green text-white'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <ChefHat className="h-4 w-4 inline-block mr-2" />
              Recettes
            </button>
              <button
    onClick={handleSendEmail}
    disabled={isLoading || !currentList}
    className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
  >
    <Mail className="h-4 w-4 mr-2" />
    Send Email
  </button>
          </div>

          {/* Archive button */}
          <button
            onClick={handleArchive}
            disabled={isLoading}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Archive className="h-4 w-4 mr-2" />
            Fermer la liste
          </button>
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {/* Add Custom Item */}
      <div>
        <AddCustomItem onAdd={addCustomItem} />
      </div>

      {/* List content */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        {viewMode === 'category' ? (
          <CategoryView items={currentList.items} recipes={allRecipes} />
        ) : (
          <RecipeView items={currentList.items} recipes={allRecipes} />
        )}
      </div>
    </div>
  );
}