import { create } from 'zustand';
import { 
  collection,
  doc,
  addDoc,
  deleteDoc,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  orderBy,
  updateDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MealPlan } from '../types';
import { useAuthStore } from './useAuthStore';
import { removeRecipeIngredients } from '../utils/mealPlanUtils';

interface MealPlanStore {
  mealPlan: MealPlan[];
  isLoading: boolean;
  error: string | null;
  addToMealPlan: (mealPlan: Omit<MealPlan, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateMealPlan: (id: string, updates: Partial<MealPlan>) => Promise<void>;
  removeFromMealPlan: (id: string) => Promise<void>;
  initializeMealPlanSubscription: () => () => void;
}

export const useMealPlanStore = create<MealPlanStore>((set, get) => ({
  mealPlan: [],
  isLoading: false,
  error: null,

  addToMealPlan: async (mealPlan) => {
    set({ isLoading: true, error: null });

    try {
      const docRef = await addDoc(collection(db, 'mealPlans'), {
        ...mealPlan,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding to meal plan:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  updateMealPlan: async (id, updates) => {
    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'mealPlans', id), {
        ...updates,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating meal plan:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  removeFromMealPlan: async (id) => {
    set({ isLoading: true, error: null });

    try {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) throw new Error('No organization ID found');

      const mealPlan = get().mealPlan.find(m => m.id === id);
      if (!mealPlan) throw new Error('Meal plan not found');

      // Remove recipe ingredients from grocery list
      await removeRecipeIngredients(mealPlan.recipeId, currentUser.defaultOrganizationId);

      // Delete meal plan
      await deleteDoc(doc(db, 'mealPlans', id));
    } catch (error) {
      console.error('Error removing from meal plan:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  initializeMealPlanSubscription: () => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) {
      console.error('No default organization ID found');
      return () => {};
    }

    const mealPlansQuery = query(
      collection(db, 'mealPlans'),
      where('organizationId', '==', currentUser.defaultOrganizationId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(mealPlansQuery, 
      (snapshot) => {
        const mealPlans: MealPlan[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          mealPlans.push({ 
            ...data, 
            id: doc.id,
            createdAt: data.createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
            updatedAt: data.updatedAt?.toDate?.()?.toISOString() || new Date().toISOString()
          } as MealPlan);
        });
        set({ mealPlan: mealPlans, error: null });
      },
      (error) => {
        console.error('Error in meal plans subscription:', error);
        set({ error: error.message });
      }
    );

    return unsubscribe;
  }
}));
