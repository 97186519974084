import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PlusCircle, Globe, Lock } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { useAuthStore } from '../../store/useAuthStore';
import { IngredientEntry } from './IngredientEntry';
import { compressImage } from '../../utils/imageCompression';

export function RecipeFormV2() {
  const { id } = useParams();
  const navigate = useNavigate();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const addRecipe = useStore((state) => state.addRecipe);
  const updateRecipe = useStore((state) => state.updateRecipe);
  const currentUser = useAuthStore((state) => state.currentUser);

  const existingRecipe = id ? [...recipes, ...publicRecipes].find(r => r.id === id) : null;

  const [name, setName] = useState(existingRecipe?.name || '');
  const [ingredients, setIngredients] = useState(existingRecipe?.ingredients || []);
  const [instructions, setInstructions] = useState(existingRecipe?.instructions || '');
  const [prepTime, setPrepTime] = useState(existingRecipe?.prepTime?.toString() || '');
  const [cookTime, setCookTime] = useState(existingRecipe?.cookTime?.toString() || '');
  const [servings, setServings] = useState(existingRecipe?.servings?.toString() || '');
  const [sourceUrl, setSourceUrl] = useState(existingRecipe?.sourceUrl || '');
  const [isPublic, setIsPublic] = useState(existingRecipe?.isPublic || false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(existingRecipe?.image || null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompressing, setIsCompressing] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setUploadError('File must be an image');
      return;
    }

    setIsCompressing(true);
    setUploadError(null);

    try {
      const compressedFile = await compressImage(file);
      setImageFile(compressedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
      setUploadError('Error processing image. Please try again.');
    } finally {
      setIsCompressing(false);
    }
  };

  const removeImage = () => {
    setImageFile(null);
    setImagePreview(null);
    setUploadError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    
    setIsSubmitting(true);
    setUploadError(null);
    
    try {
      const recipeData = {
        name,
        ingredients: ingredients.map(ingredient => ({
          ...ingredient,
          quantity: Number(ingredient.quantity)
        })),
        instructions,
        prepTime: Number(prepTime),
        cookTime: Number(cookTime),
        servings: Number(servings),
        sourceUrl: sourceUrl || null,
        image: imagePreview,
        isPublic,
        createdBy: existingRecipe?.createdBy || {
          id: currentUser.id,
          name: currentUser.name || `${currentUser.firstName} ${currentUser.lastName}`.trim()
        }
      };
      
      if (existingRecipe) {
        await updateRecipe(existingRecipe.id, recipeData);
      } else {
        await addRecipe(recipeData);
      }
      
      navigate('/recipes');
    } catch (error) {
      console.error('Error saving recipe:', error);
      setUploadError('Failed to save recipe. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddIngredient = () => {
    setIngredients((prevIngredients) => [
      ...prevIngredients,
      {
        id: crypto.randomUUID(),
        standardIngredientId: '',
        name: '',
        category: 'other',
        quantity: 1,
        unit: '',
      },
    ]);
  };

  const handleUpdateIngredient = (index: number, updates: Partial<typeof ingredients[0]>) => {
    setIngredients(ingredients.map((ingredient, i) =>
      i === index ? { ...ingredient, ...updates } : ingredient
    ));
  };

  const handleRemoveIngredient = (index: number) => {
    setIngredients(ingredients.filter((_, i) => i !== index));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700">
            Titre de la recette
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
          />
        </div>
        <div className="ml-4">
          <label className="block text-sm font-medium text-gray-700">
            Visibilité
          </label>
          <button
            type="button"
            onClick={() => setIsPublic(!isPublic)}
            className={`mt-1 inline-flex items-center px-4 py-2 rounded-md ${
              isPublic
                ? 'bg-menioo-green/10 text-menioo-green'
                : 'bg-gray-100 text-gray-800'
            }`}
          >
            {isPublic ? (
              <>
                <Globe className="h-4 w-4 mr-2" />
                Publique
              </>
            ) : (
              <>
                <Lock className="h-4 w-4 mr-2" />
                Privée
              </>
            )}
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Image
        </label>
        <div className="mt-1 flex items-center space-x-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isCompressing}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Upload Image
          </button>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Recipe preview"
              className="h-20 w-20 object-cover rounded-md"
            />
          )}
        </div>
        {uploadError && (
          <p className="mt-2 text-sm text-red-600">{uploadError}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          URL
        </label>
        <input
          type="url"
          value={sourceUrl}
          onChange={(e) => setSourceUrl(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
          placeholder="https://example.com/recipe"
        />
      </div>

      <div>
        <div className="flex items-center justify-between mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Ingrédients
          </label>
          <button
            type="button"
            onClick={handleAddIngredient}
            className="inline-flex items-center text-menioo-green hover:text-menioo-green-dark"
          >
            <PlusCircle className="h-4 w-4 mr-1" />
            Ingrédient
          </button>
        </div>
        
        <div className="space-y-2">
          {ingredients.map((ingredient, index) => (
            <IngredientEntry
              key={ingredient.id}
              value={ingredient}
              onChange={(updates) => handleUpdateIngredient(index, updates)}
              onRemove={() => handleRemoveIngredient(index)}
              onAddNew={handleAddIngredient}
              autoFocus={index === ingredients.length - 1}
              isLast={index === ingredients.length - 1}
            />
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Etapes
        </label>
        <textarea
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          required
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Temps de préparation (min)
          </label>
          <input
            type="number"
            value={prepTime}
            onChange={(e) => setPrepTime(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
           Temps de cuisson (min)
          </label>
          <input
            type="number"
            value={cookTime}
            onChange={(e) => setCookTime(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Portions
          </label>
          <input
            type="number"
            value={servings}
            onChange={(e) => setServings(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green"
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={isSubmitting || isCompressing}
        className="w-full bg-menioo-green text-white py-2 px-4 rounded-md hover:bg-menioo-green-dark focus:outline-none focus:ring-2 focus:ring-menioo-green focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting ? 'Enregistrement...' : existingRecipe ? 'Mettre à jour' : 'Ajouter la recette'}
      </button>
    </form>
  );
}