import { create } from 'zustand';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc,
  serverTimestamp,
  runTransaction
} from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import type { User, Organization, UserRole, OrganizationMember } from '../types';

interface AuthState {
  currentUser: User | null;
  currentOrganization: Organization | null;
  isAuthenticated: boolean;
  error: string | null;
  isLoading: boolean;
}

interface AuthStore extends AuthState {
  setAuthState: (state: Partial<AuthState>) => void;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, firstName: string, lastName: string, organizationName: string) => Promise<void>;
  logout: () => Promise<void>;
  switchOrganization: (organizationId: string) => Promise<void>;
  updateOrganization: (updates: Partial<Organization>) => Promise<void>;
  addUserToOrganization: (email: string, role: UserRole) => Promise<void>;
  removeUserFromOrganization: (userId: string) => Promise<void>;
  updateUserRole: (userId: string, role: UserRole) => Promise<void>;
  setDefaultOrganization: (organizationId: string) => Promise<void>;
  sendPasswordReset: (email: string) => Promise<void>;
  clearError: () => void;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  currentUser: null,
  currentOrganization: null,
  isAuthenticated: false,
  error: null,
  isLoading: false,

  setAuthState: (state) => set(state),

  clearError: () => set({ error: null }),

  sendPasswordReset: async (email: string) => {
    set({ isLoading: true, error: null });
    
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      set({ error: 'Failed to send password reset email. Please try again.' });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  login: async (email: string, password: string) => {
    set({ isLoading: true, error: null });
    
    try {
      await setPersistence(auth, browserLocalPersistence);
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);

      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      
      if (!userDoc.exists()) {
        await signOut(auth);
        set({ 
          error: 'User account not found. Please sign up.',
          isLoading: false,
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false
        });
        return;
      }

      const userData = userDoc.data() as User;
      const defaultOrgId = userData.defaultOrganizationId;
      const orgDoc = await getDoc(doc(db, 'organizations', defaultOrgId));
      
      if (!orgDoc.exists()) {
        await signOut(auth);
        set({ 
          error: 'Organization not found. Please contact support.',
          isLoading: false,
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false
        });
        return;
      }

      const orgData = orgDoc.data() as Organization;

      set({
        currentUser: { ...userData, id: userDoc.id },
        currentOrganization: { ...orgData, id: orgDoc.id },
        isAuthenticated: true,
        error: null,
        isLoading: false
      });
    } catch (error: any) {
      console.error('Login error:', error);
      let errorMessage = 'Invalid email or password.';
      if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Too many failed attempts. Please try again later.';
      }
      set({ 
        error: errorMessage,
        isLoading: false,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false
      });
    }
  },

  signup: async (email: string, password: string, firstName: string, lastName: string, organizationName: string) => {
    set({ isLoading: true, error: null });

    try {
      await setPersistence(auth, browserLocalPersistence);
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);

      const timestamp = new Date().toISOString();
      const orgId = crypto.randomUUID();

      const user: User = {
        id: firebaseUser.uid,
        email,
        firstName,
        lastName,
        emailVerified: false,
        defaultOrganizationId: orgId,
        organizations: {
          [orgId]: {
            organizationId: orgId,
            role: 'owner',
            joinedAt: timestamp
          }
        },
        createdAt: timestamp,
        updatedAt: timestamp
      };

      const member: OrganizationMember = {
        userId: firebaseUser.uid,
        email,
        name: `${firstName} ${lastName}`.trim(),
        role: 'owner',
        invitationAccepted: true,
        joinedAt: timestamp
      };

      const organization: Organization = {
        id: orgId,
        name: organizationName,
        creatorId: firebaseUser.uid,
        members: [member],
        numberOfPersons: 1,
        createdAt: timestamp,
        updatedAt: timestamp
      };

      await runTransaction(db, async (transaction) => {
        transaction.set(doc(db, 'users', firebaseUser.uid), user);
        transaction.set(doc(db, 'organizations', orgId), organization);
      });

      // Send verification email
      await sendEmailVerification(firebaseUser, {
        url: `${window.location.origin}/verify-email?uid=${firebaseUser.uid}`,
        handleCodeInApp: true
      });

      // Sign out until email is verified
      await signOut(auth);
      
      set({ 
        error: null,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false,
        isLoading: false
      });

    } catch (error: any) {
      console.error('Signup error:', error);
      let errorMessage = 'Failed to create account. Please try again.';
      
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already registered. Please sign in or use a different email.';
      }
      
      set({ 
        error: errorMessage,
        isLoading: false,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false
      });
    }
  },

  logout: async () => {
    set({ isLoading: true });
    await signOut(auth);
    set({
      currentUser: null,
      currentOrganization: null,
      isAuthenticated: false,
      error: null,
      isLoading: false
    });
  },

  switchOrganization: async (organizationId: string) => {
    const { currentUser } = get();
    if (!currentUser || !currentUser.organizations[organizationId]) return;

    set({ isLoading: true, error: null });

    try {
      const orgDoc = await getDoc(doc(db, 'organizations', organizationId));
      if (!orgDoc.exists()) {
        throw new Error('Organization not found');
      }

      const orgData = orgDoc.data() as Organization;
      
      set({
        currentOrganization: { ...orgData, id: orgDoc.id },
        isLoading: false
      });
    } catch (error) {
      console.error('Error switching organization:', error);
      set({ 
        error: 'Failed to switch organization',
        isLoading: false
      });
    }
  },

  setDefaultOrganization: async (organizationId: string) => {
    const { currentUser } = get();
    if (!currentUser) return;

    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'users', currentUser.id), {
        defaultOrganizationId: organizationId,
        updatedAt: serverTimestamp()
      });

      set(state => ({
        currentUser: state.currentUser ? {
          ...state.currentUser,
          defaultOrganizationId: organizationId
        } : null,
        isLoading: false
      }));
    } catch (error) {
      console.error('Error setting default organization:', error);
      set({ 
        error: 'Failed to update default organization',
        isLoading: false
      });
    }
  },

  updateOrganization: async (updates) => {
    const { currentOrganization } = get();
    if (!currentOrganization) return;

    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'organizations', currentOrganization.id), {
        ...updates,
        updatedAt: serverTimestamp()
      });

      set({ 
        currentOrganization: { ...currentOrganization, ...updates },
        isLoading: false
      });
    } catch (error) {
      console.error('Error updating organization:', error);
      set({ 
        error: 'Failed to update organization',
        isLoading: false
      });
    }
  },

  addUserToOrganization: async (email: string, role: UserRole) => {
    const { currentUser, currentOrganization } = get();
    if (!currentUser || !currentOrganization) return;

    set({ isLoading: true, error: null });

    try {
      const timestamp = new Date().toISOString();
      const member: OrganizationMember = {
        userId: crypto.randomUUID(), // Temporary ID until user accepts invitation
        email,
        name: email.split('@')[0],
        role,
        invitationAccepted: false,
        joinedAt: timestamp
      };

      await updateDoc(doc(db, 'organizations', currentOrganization.id), {
        members: [...currentOrganization.members, member],
        updatedAt: serverTimestamp()
      });

      set(state => ({
        currentOrganization: state.currentOrganization ? {
          ...state.currentOrganization,
          members: [...state.currentOrganization.members, member]
        } : null,
        isLoading: false
      }));
    } catch (error) {
      console.error('Error adding user:', error);
      set({ 
        error: 'Failed to add user to organization',
        isLoading: false
      });
    }
  },

  removeUserFromOrganization: async (userId: string) => {
    const { currentOrganization } = get();
    if (!currentOrganization) return;

    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'organizations', currentOrganization.id), {
        members: currentOrganization.members.filter(m => m.userId !== userId),
        updatedAt: serverTimestamp()
      });

      set(state => ({
        currentOrganization: state.currentOrganization ? {
          ...state.currentOrganization,
          members: state.currentOrganization.members.filter(m => m.userId !== userId)
        } : null,
        isLoading: false
      }));
    } catch (error) {
      console.error('Error removing user:', error);
      set({ 
        error: 'Failed to remove user from organization',
        isLoading: false
      });
    }
  },

  updateUserRole: async (userId: string, role: UserRole) => {
    const { currentOrganization } = get();
    if (!currentOrganization) return;

    set({ isLoading: true, error: null });

    try {
      const updatedMembers = currentOrganization.members.map(member =>
        member.userId === userId ? { ...member, role } : member
      );

      await updateDoc(doc(db, 'organizations', currentOrganization.id), {
        members: updatedMembers,
        updatedAt: serverTimestamp()
      });

      set(state => ({
        currentOrganization: state.currentOrganization ? {
          ...state.currentOrganization,
          members: updatedMembers
        } : null,
        isLoading: false
      }));
    } catch (error) {
      console.error('Error updating user role:', error);
      set({ 
        error: 'Failed to update user role',
        isLoading: false
      });
    }
  }
}));