import { create } from 'zustand';
import { 
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  onSnapshot,
  serverTimestamp,
  orderBy,
  where,
  or
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { compressImage } from '../utils/imageCompression';
import { StandardIngredient, CreateStandardIngredientInput, UpdateStandardIngredientInput, LanguageCode } from '../types/ingredients';

interface StandardIngredientsStore {
  ingredients: StandardIngredient[];
  searchTerm: string;
  selectedLanguages: LanguageCode[];
  isLoading: boolean;
  error: string | null;
  setSearchTerm: (term: string) => void;
  toggleLanguage: (lang: LanguageCode) => void;
  addIngredient: (input: CreateStandardIngredientInput) => Promise<void>;
  updateIngredient: (input: UpdateStandardIngredientInput) => Promise<void>;
  removeIngredient: (id: string) => Promise<void>;
  processImage: (file: File) => Promise<string>;
  initializeSubscription: () => () => void;
}

export const useStandardIngredientsStore = create<StandardIngredientsStore>((set, get) => {
  let unsubscribe: (() => void) | null = null;

  return {
    ingredients: [],
    searchTerm: '',
    selectedLanguages: ['en', 'fr'],
    isLoading: false,
    error: null,

    setSearchTerm: (term: string) => {
      set({ searchTerm: term });
    },

    toggleLanguage: (lang: LanguageCode) => {
      set(state => {
        const languages = state.selectedLanguages.includes(lang)
          ? state.selectedLanguages.filter(l => l !== lang)
          : [...state.selectedLanguages, lang];
        
        // Ensure at least one language is selected
        return { 
          selectedLanguages: languages.length > 0 ? languages : state.selectedLanguages 
        };
      });
    },

    addIngredient: async (input: CreateStandardIngredientInput) => {
      set({ isLoading: true, error: null });

      try {
        await addDoc(collection(db, 'standardIngredients'), {
          ...input,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error adding ingredient:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

updateIngredient: async (input: UpdateStandardIngredientInput) => {
  set({ isLoading: true, error: null });

  try {
    const { id, ...updates } = input;

    const sanitizedUpdates: Partial<StandardIngredient> = {
      ...updates,
      aliases: updates.aliases || [],
      picture: updates.picture || '',
      attributes: {
        glutenFree: updates.attributes?.glutenFree ?? false,
        vegan: updates.attributes?.vegan ?? false,
        vegetarian: updates.attributes?.vegetarian ?? false,
        calories: updates.attributes?.calories ?? null,
        organic: updates.attributes?.organic ?? false,
        allergens: updates.attributes?.allergens || [],
        seasonal: updates.attributes?.seasonal || [],
      },
      updatedAt: serverTimestamp(),
    };

    const sanitizedPayload = JSON.parse(JSON.stringify(sanitizedUpdates));

    await updateDoc(doc(db, 'standardIngredients', id), sanitizedPayload);
  } catch (error) {
    console.error('Error updating ingredient:', error);
    set({ error: (error as Error).message });
  } finally {
    set({ isLoading: false });
  }
}, // <--- Correctly end this method with a comma, not a semicolon


    removeIngredient: async (id: string) => {
      set({ isLoading: true, error: null });

      try {
        await deleteDoc(doc(db, 'standardIngredients', id));
      } catch (error) {
        console.error('Error removing ingredient:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    processImage: async (file: File) => {
      try {
        const compressedFile = await compressImage(file);
        
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(compressedFile);
        });
      } catch (error) {
        console.error('Error processing image:', error);
        throw error;
      }
    },

    initializeSubscription: () => {
      const { selectedLanguages } = get();

      // Create OR conditions for each selected language
      const languageConditions = selectedLanguages.map(lang => 
        where('language', '==', lang)
      );

      // Query with language filter
      const ingredientsQuery = query(
        collection(db, 'standardIngredients'),
        or(...languageConditions),
        orderBy('name', 'asc')
      );

      unsubscribe = onSnapshot(ingredientsQuery,
        (snapshot) => {
          const ingredients: StandardIngredient[] = [];
          snapshot.forEach((doc) => {
            ingredients.push({ id: doc.id, ...doc.data() } as StandardIngredient);
          });
          set({ ingredients, error: null });
        },
        (error) => {
          console.error('Error in ingredients subscription:', error);
          set({ error: error.message });
        }
      );

      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  };
});