import React, { useRef, useEffect, forwardRef } from 'react';
import { MinusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { StandardIngredient } from '../../types/ingredients';
import { IngredientSelector } from './IngredientSelector';
import { standardUnits } from '../../types/units';

interface IngredientEntryProps {
  value: {
    id: string;
    standardIngredientId: string;
    name: string;
    category: string;
    quantity: number;
    unit: string;
  };
  onChange: (updates: Partial<typeof value>) => void;
  onRemove: () => void;
  onAddNew: () => void;
  autoFocus?: boolean;
  isLast?: boolean;
}

export const IngredientEntry = forwardRef<HTMLDivElement, IngredientEntryProps>(({ 
  value, 
  onChange, 
  onRemove, 
  onAddNew,
  autoFocus = false,
  isLast = false
}, ref) => {
  const { t } = useTranslation();
  const selectorRef = useRef<HTMLDivElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const unitRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (autoFocus && selectorRef.current) {
      const searchInput = selectorRef.current.querySelector('input');
      if (searchInput instanceof HTMLInputElement) {
        searchInput.focus();
      }
    }
  }, [autoFocus]);

  const handleIngredientSelect = (ingredient: StandardIngredient) => {
    onChange({
      standardIngredientId: ingredient.id,
      name: ingredient.name,
      category: ingredient.category,
      unit: ingredient.preferredUnit
    });
    if (quantityRef.current) {
      quantityRef.current.focus();
    }
  };

  const handleQuantityKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      e.preventDefault();
      if (unitRef.current) {
        unitRef.current.focus();
      }
    }
  };

  const handleUnitKeyDown = (e: React.KeyboardEvent<HTMLSelectElement>) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
      e.preventDefault();
      if (isLast) {
        onAddNew();
        // Use requestAnimationFrame to ensure DOM is updated before focusing
        requestAnimationFrame(() => {
          const lastRow = document.querySelector('.ingredient-entry:last-child');
          const searchInput = lastRow?.querySelector('input[type="text"]');
          if (searchInput instanceof HTMLInputElement) {
            searchInput.focus();
          }
        });
      }
    }
  };

  return (
    <div 
      ref={ref}
      className="flex gap-3 items-center p-2 rounded-lg hover:bg-gray-50 transition-colors ingredient-entry"
    >
      <div className="flex-1" ref={selectorRef}>
        <IngredientSelector
          value={value}
          onChange={handleIngredientSelect}
          className="w-full"
        />
      </div>
      
      <div className="w-32">
        <input
          ref={quantityRef}
          type="number"
          value={value.quantity}
          onChange={(e) => onChange({ quantity: Number(e.target.value) })}
          onKeyDown={handleQuantityKeyDown}
          min="0"
          step="0.1"
          className="w-full h-10 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green transition-colors"
          placeholder="Quantity"
        />
      </div>
      
      <div className="w-40">
        <select
          ref={unitRef}
          value={value.unit}
          onChange={(e) => onChange({ unit: e.target.value })}
          onKeyDown={handleUnitKeyDown}
          className="w-full h-10 px-3 rounded-md border border-gray-300 shadow-sm focus:border-menioo-green focus:ring-1 focus:ring-menioo-green transition-colors appearance-none bg-white"
        >
          <option value="">Select unit</option>
          {Object.entries(standardUnits).map(([unit, translation]) => (
            <option key={unit} value={unit}>
              {t(`units.${unit}`)}
            </option>
          ))}
        </select>
      </div>

      <button
        type="button"
        onClick={onRemove}
        className="flex-shrink-0 h-10 w-10 flex items-center justify-center text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
        title="Remove ingredient"
      >
        <MinusCircle className="h-5 w-5" />
      </button>
    </div>
  );
});

IngredientEntry.displayName = 'IngredientEntry';