import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { X, Upload } from 'lucide-react';
import { StandardIngredient, LanguageCode } from '../../types/ingredients';
import { IngredientCategory } from '../../types';

interface IngredientFormProps {
  ingredient?: StandardIngredient;
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
  processImage: (file: File) => Promise<string>;
}

export function IngredientForm({ ingredient, onSubmit, onCancel, processImage }: IngredientFormProps) {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<LanguageCode>(ingredient?.language || 'en');
  const [imagePreview, setImagePreview] = useState<string | null>(ingredient?.picture || null);
  const [allergens, setAllergens] = useState<string[]>(ingredient?.attributes.allergens || []);
  const [newAllergen, setNewAllergen] = useState('');
  const [seasonal, setSeasonal] = useState<string[]>(ingredient?.attributes.seasonal || []);
  const [newSeasonal, setNewSeasonal] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        try {
          const base64Image = await processImage(file);
          setImagePreview(base64Image);
        } catch (error) {
          console.error('Error processing image:', error);
          alert('Error processing image. Please try again.');
        }
      }
    }
  });

  const handleAddAllergen = () => {
    if (newAllergen.trim()) {
      setAllergens([...allergens, newAllergen.trim()]);
      setNewAllergen('');
    }
  };

  const handleRemoveAllergen = (index: number) => {
    setAllergens(allergens.filter((_, i) => i !== index));
  };

  const handleAddSeasonal = () => {
    if (newSeasonal.trim()) {
      setSeasonal([...seasonal, newSeasonal.trim()]);
      setNewSeasonal('');
    }
  };

  const handleRemoveSeasonal = (index: number) => {
    setSeasonal(seasonal.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const formData = new FormData(e.currentTarget);
      
      const data = {
        name: formData.get('name'),
        language,
        category: formData.get('category'),
        aliases: formData.get('aliases')?.toString().split(',').map(s => s.trim()).filter(Boolean) || [],
        preferredUnit: formData.get('preferredUnit'),
        picture: imagePreview,
        attributes: {
          glutenFree: formData.get('glutenFree') === 'on',
          vegan: formData.get('vegan') === 'on',
          vegetarian: formData.get('vegetarian') === 'on',
          calories: formData.get('calories') ? Number(formData.get('calories')) : undefined,
          organic: formData.get('organic') === 'on',
          allergens,
          seasonal
        }
      };

      await onSubmit(data);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error saving ingredient. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Language Selector */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy">
          Language
        </label>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value as LanguageCode)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>

      {/* Name */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy">
          Name
        </label>
        <input
          type="text"
          name="name"
          defaultValue={ingredient?.name}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
        />
      </div>

      {/* Category */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy">
          Category
        </label>
        <select
          name="category"
          defaultValue={ingredient?.category || 'other'}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
        >
          {Object.keys(t('categories', { returnObjects: true })).map((category) => (
            <option key={category} value={category}>
              {t(`categories.${category}`)}
            </option>
          ))}
        </select>
      </div>

      {/* Aliases */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy">
          Aliases (comma-separated)
        </label>
        <input
          type="text"
          name="aliases"
          defaultValue={ingredient?.aliases.join(', ')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
        />
      </div>

      {/* Preferred Unit */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy">
          Preferred Unit
        </label>
        <select
          name="preferredUnit"
          defaultValue={ingredient?.preferredUnit || 'piece'}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
        >
          {Object.keys(t('units', { returnObjects: true })).map((unit) => (
            <option key={unit} value={unit}>
              {t(`units.${unit}`)}
            </option>
          ))}
        </select>
      </div>

      {/* Image Upload */}
      <div>
        <label className="block text-sm font-medium text-menioo-navy mb-2">
          Image
        </label>
        <div
          {...getRootProps()}
          className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-menioo-green transition-colors cursor-pointer"
        >
          <div className="space-y-1 text-center">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex text-sm text-gray-600">
              <input {...getInputProps()} />
              <p>Drag and drop an image, or click to select</p>
            </div>
          </div>
        </div>
        {imagePreview && (
          <div className="mt-2 relative inline-block">
            <img
              src={imagePreview}
              alt="Preview"
              className="h-24 w-24 object-cover rounded-md"
            />
            <button
              type="button"
              onClick={() => setImagePreview(null)}
              className="absolute -top-2 -right-2 bg-red-100 rounded-full p-1"
            >
              <X className="h-4 w-4 text-red-600" />
            </button>
          </div>
        )}
      </div>

      {/* Attributes */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-menioo-navy">Attributes</h3>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="glutenFree"
                defaultChecked={ingredient?.attributes.glutenFree}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="ml-2 text-sm text-gray-700">Gluten Free</span>
            </label>
          </div>
          
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="vegan"
                defaultChecked={ingredient?.attributes.vegan}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="ml-2 text-sm text-gray-700">Vegan</span>
            </label>
          </div>
          
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="vegetarian"
                defaultChecked={ingredient?.attributes.vegetarian}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="ml-2 text-sm text-gray-700">Vegetarian</span>
            </label>
          </div>
          
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="organic"
                defaultChecked={ingredient?.attributes.organic}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="ml-2 text-sm text-gray-700">Organic</span>
            </label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-menioo-navy">
            Calories (per 100g/100ml)
          </label>
          <input
            type="number"
            name="calories"
            defaultValue={ingredient?.attributes.calories}
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
          />
        </div>

        {/* Allergens */}
        <div>
          <label className="block text-sm font-medium text-menioo-navy">
            Allergens
          </label>
          <div className="mt-1 flex space-x-2">
            <input
              type="text"
              value={newAllergen}
              onChange={(e) => setNewAllergen(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              placeholder="Add allergen"
            />
            <button
              type="button"
              onClick={handleAddAllergen}
              className="px-4 py-2 bg-menioo-green text-white rounded-md hover:bg-menioo-green-dark"
            >
              Add
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {allergens.map((allergen, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
              >
                {allergen}
                <button
                  type="button"
                  onClick={() => handleRemoveAllergen(index)}
                  className="ml-1 text-red-600 hover:text-red-900"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>
        </div>

        {/* Seasonal */}
        <div>
          <label className="block text-sm font-medium text-menioo-navy">
            Seasonal Availability
          </label>
          <div className="mt-1 flex space-x-2">
            <input
              type="text"
              value={newSeasonal}
              onChange={(e) => setNewSeasonal(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              placeholder="Add season"
            />
            <button
              type="button"
              onClick={handleAddSeasonal}
              className="px-4 py-2 bg-menioo-green text-white rounded-md hover:bg-menioo-green-dark"
            >
              Add
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {seasonal.map((season, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                {season}
                <button
                  type="button"
                  onClick={() => handleRemoveSeasonal(index)}
                  className="ml-1 text-green-600 hover:text-green-900"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-menioo-green rounded-md hover:bg-menioo-green-dark disabled:opacity-50"
        >
          {isSubmitting ? 'Saving...' : ingredient ? 'Update Ingredient' : 'Add Ingredient'}
        </button>
      </div>
    </form>
  );
}