import React from 'react';
import { Check, Crown } from 'lucide-react';

const plans = [
  {
    name: 'Gratuit',
    price: '0€',
    features: [
      'Jusqu\'à 50 recettes',
      'Planification de base',
      'Liste de courses',
      'Jusqu\'à 2 membres'
    ],
    current: true
  },
  {
    name: 'Pro',
    price: '9.99€',
    interval: 'mois',
    features: [
      'Recettes illimitées',
      'Planification avancée',
      'Liste de courses intelligente',
      'Informations nutritionnelles',
      'Adaptation des portions',
      'Jusqu\'à 5 membres',
      'Support prioritaire'
    ]
  },
  {
    name: 'Entreprise',
    price: '19.99€',
    interval: 'mois',
    features: [
      'Toutes les fonctionnalités Pro',
      'Membres illimités',
      'Personnalisation de la marque',
      'Accès API',
      'Analyses avancées',
      'Support dédié',
      'Fonctionnalités sur mesure'
    ]
  }
];

export function SubscriptionPlans() {
  return (
    <div className="bg-white shadow-sm rounded-xl p-6 border border-gray-100">
      <div className="flex items-center space-x-3 mb-6">
        <Crown className="h-6 w-6 text-menioo-green" />
        <h2 className="text-xl font-display font-bold text-menioo-navy">
          Abonnements
        </h2>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={`rounded-lg border ${
              plan.current
                ? 'border-menioo-green ring-2 ring-menioo-green'
                : 'border-gray-200'
            } p-6 space-y-4`}
          >
            <div className="text-center">
              <h3 className="text-lg font-display font-semibold text-menioo-navy">
                {plan.name}
              </h3>
              <div className="mt-2">
                <span className="text-3xl font-bold text-menioo-navy">
                  {plan.price}
                </span>
                {plan.interval && (
                  <span className="text-gray-500 ml-2">/{plan.interval}</span>
                )}
              </div>
            </div>

            <ul className="space-y-3">
              {plan.features.map((feature) => (
                <li key={feature} className="flex items-center">
                  <Check className="h-4 w-4 text-menioo-green mr-2 flex-shrink-0" />
                  <span className="text-sm text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              className={`w-full py-2 px-4 rounded-md text-sm font-medium transition-colors ${
                plan.current
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-menioo-green text-white hover:bg-menioo-green-dark'
              }`}
              disabled={plan.current}
            >
              {plan.current ? 'Plan actuel' : 'Changer de plan'}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 text-center text-sm text-gray-500">
        * Tous les prix sont en EUR et hors TVA
      </div>
    </div>
  );
}