import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Recipe } from '../../types';
import { RecipeTile } from './RecipeTile';

interface DraggableRecipeProps {
  recipe: Recipe;
  index: number;
  mealPlanId?: string;
}

export function DraggableRecipe({ recipe, index, mealPlanId }: DraggableRecipeProps) {
  // Use mealPlanId in draggableId to make each instance unique
  const draggableId = mealPlanId ? `${recipe.id}-${mealPlanId}` : recipe.id;

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.5 : 1
          }}
        >
          <RecipeTile 
            recipe={recipe} 
            mealPlanId={mealPlanId}
            onRemoveFromDay={!!mealPlanId}
          />
        </div>
      )}
    </Draggable>
  );
}