import React, { useState, useMemo } from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { format, addDays, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ChevronLeft, ChevronRight, Search, ShoppingCart } from 'lucide-react';
import { useStore } from '../../store/useStore';
import { useMealPlanStore } from '../../store/useMealPlanStore';
import { DraggableRecipe } from './DraggableRecipe';
import { WeeklyPlanner } from './WeeklyPlanner';
import { useAuthStore } from '../../store/useAuthStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { GroceryListSidebar } from './GroceryListSidebar';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';

export function MealPlanDrag() {
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));
  const [searchTerm, setSearchTerm] = useState('');
  const [isGroceryListOpen, setIsGroceryListOpen] = useState(true);
  const recipes = useStore((state) => state.recipes) || [];
  const publicRecipes = useStore((state) => state.publicRecipes) || [];
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);
  const mealPlan = useMealPlanStore((state) => state.mealPlan);
  const updateMealPlan = useMealPlanStore((state) => state.updateMealPlan);
  const currentUser = useAuthStore((state) => state.currentUser);

  const cookbookRecipes = useMemo(() => {
    const allRecipes = [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
    
    if (!searchTerm) return allRecipes;
    
    const normalizedSearch = searchTerm.toLowerCase();
    return allRecipes.filter(recipe => 
      recipe.name.toLowerCase().includes(normalizedSearch)
    );
  }, [recipes, publicRecipes, publicRecipeIds, searchTerm]);

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

  const handlePreviousWeek = () => {
    setStartDate(date => addDays(date, -7));
  };

  const handleNextWeek = () => {
    setStartDate(date => addDays(date, 7));
  };

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    // Dropped outside a valid drop zone or no user
    if (!destination || !currentUser?.defaultOrganizationId) return;

    try {
      // Handle drag from recipe list to meal plan
      if (source.droppableId === 'recipes') {
        const recipe = cookbookRecipes.find(r => r.id === draggableId);
        if (!recipe) return;

        let destDate = startDate.toISOString().split('T')[0];
        let destMealType = '';

        if (destination.droppableId !== 'unplanned') {
          [destDate, destMealType] = destination.droppableId.split('_');
        }

        await addRecipeToMealPlanAndGrocery(
          recipe,
          destDate,
          destMealType,
          currentUser.id,
          currentUser.defaultOrganizationId
        );
      } 
      // Handle drag between meal plan slots
      else {
        const [recipeId, mealPlanId] = draggableId.split('-');
        if (!mealPlanId) return;

        if (destination.droppableId === 'unplanned') {
          await updateMealPlan(mealPlanId, {
            date: '',
            mealType: ''
          });
        } else {
          const [destDate, destMealType] = destination.droppableId.split('_');
          await updateMealPlan(mealPlanId, {
            date: destDate,
            mealType: destMealType
          });
        }
      }
    } catch (error) {
      console.error('Error updating meal plan:', error);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-display font-bold text-menioo-navy">
            Mes recettes
          </h2>
          <button
            onClick={() => setIsGroceryListOpen(!isGroceryListOpen)}
            className="p-2 rounded-full bg-white shadow-sm border border-gray-200 hover:bg-gray-50 transition-colors"
            aria-label={isGroceryListOpen ? 'Hide grocery list' : 'Show grocery list'}
          >
            <ShoppingCart className="h-5 w-5 text-menioo-navy" />
          </button>
        </div>

        {/* Search bar moved below the title */}
        <div className="relative w-2/3 mb-4">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            type="search"
            placeholder="Chercher une recette..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-menioo-green focus:border-menioo-green"
          />
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="recipes" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4"
              >
                {cookbookRecipes.map((recipe, index) => (
                  <DraggableRecipe 
                    key={recipe.id} 
                    recipe={recipe} 
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className="flex items-center justify-between mt-8">
            <button
              onClick={handlePreviousWeek}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            <h3 className="text-lg font-medium">
              Semaine du {format(startDate, 'd MMM, yyyy', { locale: fr })}
            </h3>
            <button
              onClick={handleNextWeek}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>

          <div className="flex gap-6">
            <div className={`flex-1 transition-all duration-300 ${isGroceryListOpen ? 'mr-80' : ''}`}>
              <WeeklyPlanner 
                weekDays={weekDays} 
                mealPlan={mealPlan} 
                recipes={cookbookRecipes} 
              />
            </div>

            <div 
              className={`fixed top-0 right-0 h-screen w-80 transform transition-transform duration-300 ease-in-out ${
                isGroceryListOpen ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <GroceryListSidebar />
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
}