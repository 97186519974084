import React from 'react';
import { ChefHat } from 'lucide-react';
import { useStore } from '../store/useStore';
import { useNavigate } from 'react-router-dom';
import { RecipeCard } from './recipe/RecipeCard';
import { useMealPlanStore } from '../store/useMealPlanStore';
import { useCookbookStore } from '../store/useCookbookStore';
import { useAuthStore } from '../store/useAuthStore';
import { Recipe } from '../types';

export function PublicRecipes() {
  const navigate = useNavigate();
  const publicRecipes = useStore((state) => state.publicRecipes);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const addToMealPlan = useMealPlanStore((state) => state.addToMealPlan);
  const addToCookbook = useCookbookStore((state) => state.addToCookbook);
  const isInCookbook = useCookbookStore((state) => state.isInCookbook);

  const handleAddToMealPlan = async (recipe: Recipe) => {
    if (!isAuthenticated || !currentUser) {
      navigate('/login');
      return;
    }

    try {
      // First add to cookbook if not already there
      if (!isInCookbook(recipe.id)) {
        await addToCookbook(recipe.id);
      }
      
      // Then add to meal plan
      await addToMealPlan({
        recipeId: recipe.id,
        date: new Date().toISOString().split('T')[0],
        mealType: 'dinner',
        userId: currentUser.id,
        organizationId: currentUser.defaultOrganizationId
      });
    } catch (error) {
      console.error('Error adding to meal plan:', error);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-3 mb-6">
        <ChefHat className="h-8 w-8 text-menioo-green" />
        <h1 className="text-3xl font-display font-bold text-menioo-navy">
          Decouvrez nos recettes !
        </h1>
      </div>

      {publicRecipes.length === 0 ? (
        <div className="min-h-[40vh] flex flex-col items-center justify-center bg-white rounded-xl shadow-sm p-8">
          <ChefHat className="h-16 w-16 text-menioo-green/20 mb-4" />
          <p className="text-menioo-navy font-medium">No recipes available yet.</p>
          <p className="text-gray-500 text-sm mt-2">Check back soon for delicious recipes!</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {publicRecipes.map((recipe) => (
            <RecipeCard
              key={recipe.id}
              recipe={recipe}
              canEdit={false}
              isDiscoveryPage={true}
              onDelete={async () => {}}
              onAddToMealPlan={handleAddToMealPlan}
            />
          ))}
        </div>
      )}
    </div>
  );
}