import React from 'react';
import { Check } from 'lucide-react';
import { Recipe } from '../../types';
import { useGroceryListStore } from '../../store/useGroceryListStore';

interface RecipeViewProps {
  items: any[];
  recipes: Recipe[];
}

const ADDITIONAL_ITEMS_ID = 'additional-items';

export function RecipeView({ items, recipes }: RecipeViewProps) {
  const toggleItem = useGroceryListStore((state) => state.toggleItem);

  // Create recipe lookup map
  const recipeLookup = React.useMemo(() => {
    return recipes.reduce((acc, recipe) => {
      acc[recipe.id] = recipe;
      return acc;
    }, {} as Record<string, Recipe>);
  }, [recipes]);

  // Group items by recipe and preserve original quantities
  const groupedByRecipe = React.useMemo(() => {
    const grouped: Record<string, any[]> = {};

    items.forEach(item => {
      if (!item.recipeIds?.length) return;
      
      // Create separate entries for each recipe
      item.recipeIds.forEach((recipeId: string) => {
        if (!grouped[recipeId]) {
          grouped[recipeId] = [];
        }

        // Find the original quantity from the recipe
        const recipe = recipeLookup[recipeId];
        const recipeIngredient = recipe?.ingredients?.find(
          ing => ing.name.toLowerCase() === item.name.toLowerCase()
        );

        grouped[recipeId].push({
          ...item,
          uniqueId: `${item.id}-${recipeId}`, // Add unique ID for each recipe-item combination
          quantity: recipeIngredient?.quantity || item.quantity / (item.recipeIds.length || 1),
          originalUnit: recipeIngredient?.unit || item.unit
        });
      });
    });

    // Sort recipes by name, with Additional Items at the end
    return Object.entries(grouped)
      .sort(([idA, _], [idB, __]) => {
        if (idA === ADDITIONAL_ITEMS_ID) return 1;
        if (idB === ADDITIONAL_ITEMS_ID) return -1;
        const nameA = recipeLookup[idA]?.name || '';
        const nameB = recipeLookup[idB]?.name || '';
        return nameA.localeCompare(nameB);
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, any[]>);
  }, [items, recipeLookup]);

  return (
    <div className="divide-y divide-gray-100">
      {Object.entries(groupedByRecipe).map(([recipeId, recipeItems]) => {
        // Special handling for Additional Items
        const isAdditionalItems = recipeId === ADDITIONAL_ITEMS_ID;
        const recipeName = isAdditionalItems ? "Additional Items" : recipeLookup[recipeId]?.name;
        
        if (!recipeName) return null;

        return (
          <div key={recipeId} className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-display font-semibold text-menioo-navy">
                {recipeName}
              </h3>
              <div className="text-sm text-gray-500">
                {recipeItems.length} items
              </div>
            </div>
            <ul className="space-y-3">
              {recipeItems
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => (
                  <li key={item.uniqueId} className="flex items-start group">
                    <button
                      onClick={() => toggleItem(item.id)}
                      className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                        item.checked
                          ? 'bg-menioo-green border-menioo-green'
                          : 'border-gray-300 group-hover:border-menioo-green'
                      }`}
                    >
                      {item.checked && <Check className="h-3 w-3 text-white" />}
                    </button>
                    <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                      <div className="text-menioo-navy">
                        <span className="font-medium">
                          {item.quantity} {item.originalUnit || item.unit}
                        </span>{' '}
                        {item.name}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
